.landing .header-section {
  display: flex;
  height: 90vh;
  background: #4D2BFF;
  border-radius: 0px 0px 60px 60px;
  width: 95%;
  margin: 0 auto;
}

.landing .header-block {
  display: flex;
  width: 100%;
  max-width: 1050px;
  margin-bottom: 140px;
  justify-content: space-between;
  align-items: center;
  margin: auto;
  padding-left: 30px;
  padding-right: 30px;
}

.landing .header-block  img {
  height: 400px;
}

.landing .block-content {
  display: flex;
  flex-direction: column;
  margin-top: 60px;
  margin-bottom: 60px;
  max-width: 1050px;
  align-items: center;
  justify-content:center;
  margin: 0 auto;
}

.block-content.single-feature.top-margin {
  margin-top: 120px;
}

.block-content.top-bot-margin {
  padding-top: 120px;
  padding-bottom: 120px;
}

.block-content.bot-margin {
  padding-bottom: 120px;
}

.round-background {
  position: relative;
  top: 0; left: 0; bottom: 0; right: 0;
  margin: auto;
  width: 95%;
  background-image: linear-gradient(180deg,#fff,hsla(0,0%,100%,0));
  border-radius: 60px 60px 0 0;
}

.bouncing-element {
  animation: MoveUpDown 5s linear infinite;
  font-size: 100px;
  position: absolute;
  left: auto;
  top: -4%;
  right: 8%;
  bottom: auto;
}

@keyframes MoveUpDown {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-30px);
  }
}

.header-section .hero-title{
  color: #fff;
  font-size: 56px;
  line-height: 70px;
  margin: 20px 0 10px;
  line-height: 74px;
  font-weight: 800;
  width: 100%;
  max-width: 600px;
}

.header-section .hero-text {
  margin-top: 30px;
  color: #bfbfbf;
  font-size: 18px;
  line-height: 30px;
  max-width: 500px;
}

.header-section .hero-btn {
  background: #2bffcc;
  margin-top: 60px;
}

.landing .section-title {
  margin-top: 20px;
  margin-bottom: 10px;
  color: #191b35;
  font-size: 48px;
  line-height: 60px;
  font-weight: 600;
}

.landing .section-title.no-margin {
  margin-top: 0px;
  margin-bottom: 0px;
}

.landing .text-span {
  background-position: 50% 100%;
  background-size: contain;
  background-repeat: no-repeat;
  color: #2bffcc;
  text-decoration: underline;
} 

.block-content .how-it-works {
  margin-top: 80px;
  margin-bottom: 80px;
  width: 100%;
  max-width: 1050px;
  width: 95%;
}

.block-content .how-it-works .img {
  height: 300px;
}


.block-content .single-feature-block {
  max-width: 400px;
}

.block-content .single-feature-block.order-1 {
  order: 2;
}

.advanced-splash .block-content .features {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(3, 1fr);
  margin-top: 80px;
}

.landing .pricing-modal {
  width: 100%;
  max-width: 1050px;
  margin: 0 auto;
}

.landing .pricing-modal .pricing-modal-container {
  margin-top: 80px;
  padding: 0px;
  width: 95%;
}

.landing .testimonial {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 20px;
  margin-top: 80px;
  width: 95%;
}

.landing .testimonial .img {
  height: 64px;
  border-radius: 50%;
}

@media screen and (max-width: 768px) {
  .advanced-splash .block-content .features {
    grid-template-columns: repeat(1, 1fr);
  }
  .landing .header-block {
   flex-direction: column;
   padding-left: 20px;
   padding-right: 20px;
   padding-top: 64px;
  }
  .landing .header-block img {
    display: none;
  }
  .block-content .how-it-works {
    flex-direction: column;
  }
  .block-content .single-feature-block {
    order: 1;
  }
  .block-content .single-feature-block {
    max-width: 500px;
  }
  .block-content .how-it-works .img {
    width: 100%;
    height: auto;
  }
  .landing .section-title {
    font-size: 34px;
    line-height: 45px;
  }
  .bouncing-element {
    top: -2%;
  }
  .block-content.top-bot-margin {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  .landing .header-section {
    height: 95vh;
  }
  .header-section .hero-title {
    font-size: 48px;
    line-height: 60px;
    margin: 0 0 10px;
  }
  .header-section .hero-btn {
    margin-top: 50px;
  }
}