.header-navigation {
	display: flex;
	justify-content: center;
	/*position: fixed;*/
	position: relative;
	z-index: 9999;
	width: 100%;
	top: 0;
	background-color: white;
}

.header-shadow {
	box-shadow: 0 2px 4px -1px rgba(0,0,0,0.25);
}

.header-navigation .header-content {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	max-width: 1050px;
	margin-bottom: 40px;
}

.header-navigation .header-logo {
	margin-right: 0px;
}

.header-navigation .header-logo.logo-white {display: none;}

.header-navigation .header-links {
	display: flex;
	align-items: center;
}

.header-navigation .nav-link {
	padding: 0px 15px;
	color: black;
	font-size: 15px;
	font-weight: 500;
	letter-spacing: .5px;
	cursor: pointer;
	transition: all 0.3s ease-out;
}

.header-navigation .nav-link:hover {
	font-weight: 600;
}

.header-navigation .header-link {
	text-transform: uppercase;
	font-size: 13px;
	color: white;
	margin: 30px;
	letter-spacing: 2px;
	font-family: 'Montserrat', sans-serif;
	border-radius: 10px;
}

.header-navigation .header-link.login {
	margin: 30px 20px 30px 20px;
}

.header-navigation .header-link:hover{
	cursor: pointer;		
}

.home-header .header-title {
	font-size: 50px;
	margin-bottom: 20px;
	color: black;
	line-height: 65px;
	font-weight: 500;
	text-transform: uppercase;
	width: 100%;
	max-width: 1200px;
	font-style: italic;
}

.alvaro-fitness-logo {
  height: 30px;
 }
 
 .header-link.black {
   color: black;
 }
 
 .header-link.white {
   color: white;
 }
 
 .header-navigation .active-menu-item {
	 font-weight: bold;
 }

 .header-navigation span {
	position: relative;
  display: block;
	cursor: pointer;
	line-height: 15px;
}

.header-navigation span:before, .header-navigation span:after {
  content: '';
  position: absolute;
  width: 0%;
  height: 1px;
  top: 50%;
  margin-top: -0.5px;
  background: #d0d0d0;
}

.header-navigation span:before {
  left: -2.5px;
}

.header-navigation span:after {
  right: 2.5px;
  background: #d0d0d0;
  transition: width 0.8s cubic-bezier(0.22, 0.61, 0.36, 1);
}

.header-navigation span:hover:before {
  background: #d0d0d0;
  width: 100%;
  transition: width 0.5s cubic-bezier(0.22, 0.61, 0.36, 1);
}

.header-navigation span:hover:after {
  background: transparent;
  width: 100%;
  transition: 0s;
}

.header-navigation .login-btn {
	margin: 0;
	padding: 6px 10px;
	border-radius: 10px;
	text-transform: uppercase;
	font-weight: 600;
}

.login-form .login-content{
	display: flex;
 	flex-direction: column;
}

.login-form .login-content .logo{
	 height: 32px;
	 margin: 0 auto;
	 margin-bottom: 30px;
}

.login-form .text {
	font-size: 13px;
	padding: 0 3px 0 3px;
}

.ant-layout-header {
  background: white;
	background-color: white;
  height: 120px;
  z-index: 10 !important;
  box-shadow: 0 2px 2px -5px rgba(0,0,0,.3);
}
.header-navigation.login-header {height: 80px;}
.header-navigation.login-header .header-content {margin-bottom: 5px;}

.dashboard-header {
	z-index: 2;
	height: 64px;
	background-color: white;
}

.dashboard-header .bell {
	padding: 0 30px 0 15px;
}

.dashboard-header .bell svg {
	font-size: 28px;
	fill: #000;
}

.dashboard-header .user-dropdown {
  display: flex;
	align-items: center;
	justify-content: flex-end;
	height: 100%;
}

.dashboard-header .page-header-menu-item {
	cursor: pointer;
	padding: 0 15px;
	color: #000;
}


.dashboard-header .menu-right {
	display: flex;
}

.header-navigation .header-logo .mobile-menu-icon{
	display: none;
}

.notification-wrap{
	display: flex;
	justify-content: space-between;
	border-bottom:1px solid #d3d3d3;
	padding: 10px 0px;
	margin-bottom: 0px;
}
.notification-section{
	height: auto;
	max-height: 400px;
	overflow-x: hidden;
	overflow-y: scroll;
	-webkit-overflow-scrolling: touch;
	&::-webkit-scrollbar-track {
		-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
		border-radius: 10px;
		background-color: #F5F5F5;
	}

	&::-webkit-scrollbar {
		width: 8px;
		background-color: #ccc;
	}

	&::-webkit-scrollbar-thumb {
		border-radius: 10px;
		-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
		background-color: #ccc;
	}

	ul{
		list-style: none;
	}
	.notification-profile {
		cursor: pointer;
		.notification-profile-wrap {
			font-family: 'Montserrat', sans-serif;
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 10px 0px 10px 0px;
			border-bottom: 1px solid #f3f3f3;
			.notification-profile-img {
				position: relative;
				height: 50px;
				.notification-status {
					position: absolute;
					bottom: 5px;
					right: -4px;
					height: 15px;
					width: 15px;
					border: 2px solid #fff;
					background-color: #2bffcc;
					border-radius: 50%;
				}
			}
			.notification-info-wrap {
				margin-left: 10px;
				padding: 5px 0;

				h5 {
					font-size: 14px;
					margin: 0;
				}
				.notification-date{
					padding-left: 10px;
					font-size: 11px;
				}
			}
		}
		.notification-profile-wrap:last-child {
			border-bottom: 0px;
		}
	}
	.pdf-file-icon{
		display: flex;
		background-color: #cccccc38;
		padding: 5px 10px;
		border-radius: 10px;
		margin: 10px 0 10px 45px;
		svg{
			font-size: 30px;
		}
	}
	.image-size{
		margin: 0 0 0 45px;
		width: 87%;
		height:auto;
		border-radius: 10px;
	}
	.delete-btn{
		display: flex;
		justify-content: flex-end;
		font-size: 20px;
		padding: 0 15px;
		color: red;
		button{
			background-color: red;
			border-radius: 5px;
			border: 0;
			padding: 5px 10px;
			color: #ffffff;
			box-shadow: none;
		}
	}
}
.popover-wrap{
	top: 59px !important;
	right: 15px !important;
	//transform-origin:unset !important;
	left: unset !important;
	.ant-popover-content{
		.ant-popover-arrow{
			left: 69%;
			top: 7px;
		}
	}
	&.ant-popover{
		max-width: 400px!important;
	}
	.ant-popover-inner{
		border-radius: 20px;
	}
}


// @keyframes glowing {
//   0% { box-shadow: 0 0 -10px #5400fc; }
//   40% { box-shadow: 0 0 20px #5400fc; }
//   60% { box-shadow: 0 0 20px #5400fc; }
//   100% { box-shadow: 0 0 -10px #5400fc; }
// }

// .button-glow {
//   animation: glowing 5000ms infinite;
// }


@media screen and (max-width: 1050px) {
	.header-navigation .header-logo .mobile-menu-icon {color: #d0d0d0;display: block;}
}

@media screen and (max-width: 768px) {
	.ant-layout-header {padding: 0px;}
	.dashboard-header .user-dropdown {padding-right: 20px;}

	.header-navigation .header-logo.logo-black{display: none;}
	.header-navigation .header-logo.logo-white {display: flex;}
	.header-navigation .header-logo {margin-left: 30px;margin-right: 30px;display: flex;justify-content: space-between;align-items: center;}
	.header-navigation .header-links {display: block; text-align: center; background: white; height: auto; padding-bottom: 25px;}
	.header-navigation .header-content {justify-content: center; margin-bottom: 30px;}	
}

@media screen and (max-width:425px) {
	.popover-wrap{
		&.ant-popover{
			max-width: 90%!important;
		}
		.ant-popover-content{
			.ant-popover-arrow{
				left: 71%;
			}
		}
	}
}