
.Toastify__toast.Toastify__toast--success {
  border-radius: 10px;
  background: #2761f1;
  font-family: 'Montserrat', sans-serif;
}

.Toastify__toast.Toastify__toast--warning {
  border-radius: 10px;
  font-family: 'Montserrat', sans-serif;
  color: white;
}

.Toastify__toast-body {
  font-size: 14px!important;
  font-weight: 500;
  line-height: 16px;
  border-radius: 10px;
  color: white;
}