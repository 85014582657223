/* Some vars */
// $background-color: hsl(50, 5, 97);
// $black: hsl(200, 40, 10);
// $white: $background-color;
$base-font-size: 2.4em;
$base-line-height: 1.5em;

.blog-post-container {
  grid-column: 1/13;
  width: 100%;
  maxWidth: 700px;
  margin: 0 auto;
  marginTop: 120px;
}

.blog-post-container .header-image {
  height: 225px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border-radius: 10px;
  margin-bottom: 20px;
}

.blog-post-container .blog-body {
  margin-bottom: 60px;
}

.blog-post-container .blog-body p{
  color: rgb(43, 54, 62);
  margin-bottom: 30px;
  line-height: 1.75;
  font-size: 16px;
  font-family: 'Montserrat', sans-serif;
}

.blog-post-container .blog-body h2{
  margin-bottom: 15px;
  font-weight: 500;
  font-size: 28px;
  z-index: 2;
  line-height: 32px;
}

.blog-post-container .related-posts {
  display: flex;
  flex-wrap: wrap;
  margin-top: 60px;
}

.back-to-posts {
  grid-column: 1/13;
  font-size: 14px;
}

.blog-title-section {
  grid-column: 1/13;
}

.blog-title-section .author {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.blog-title-section .title {
  font-size: 28px;
  margin-bottom: 30px;
  line-height: 40px;
  text-transform: uppercase;
  font-weight: 500;
}

.share-buttons {
  margin-bottom: -10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.share-buttons svg {
  height: 30px;
}

.share-button button.react-share__ShareButton {
  margin: 0 auto!important;
}

.blog-title-section .author-image {
  border-radius: 50%;
  height: 45px;
  margin-right: 10px;
}

.blog-title-section .author-name {
  font-size: 14px;
}

.blog-title-section .author-name .name {
  color: #000000;
}

.blog-title-section .author-name .date {
  color: #7f7f7f;
  font-size: 13px;
}

.blog-post-container .kg-image {
  margin: 15px 0 15px 0;
}

.blog-post-container .kg-card.kg-embed-card {
  margin-top: 30px;
}

@media screen and (max-width: 768px) {
  .blog-title-section {
    grid-column: none;
  }
  .blog-title-section .author {
    display: block;
  }
  .share-buttons {
   width: 100%;
   margin-bottom: 5px;
   margin-top: 10px;
   justify-content: flex-start;
  }
  .share-buttons svg {
    height: 40px;
    margin-left: 5px;
  }
  .blog-title-section .title {
    margin-bottom: 10px;
  }
}
