.settings-page .account-settings .title {
  text-align: left;
  font-weight: 500;
  font-size: 16px;
  color: #000;
  line-height: 16px;
}

.settings-page .account-settings .subtitle {
  font-size: 14px;
  font-weight: normal;
  color: #5c5c5c;
  margin-bottom: 0;
}


@media screen and (max-width: 768px) {
  .account-settings .measure-system {
    display: block;
  }
  .settings-page .account-settings .subtitle {
    margin-bottom: 10px;
  }
}