.sidebar {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-top: 20px;
  z-index: 9999;
}

.menu-link.sidebar-active {
  /* background: #21d0b2; */
  background: black;
  border-radius: 10px;
  font-weight: 600!important;
  color: white!important;
}

.sidebar .menu-items .menu-link.smart-coach,
.sidebar .menu-items .menu-link.smart-coach:hover {
  border: 1px solid white;;
  border-radius: 10px;
  font-weight: 600;
  color: white;
  background: none;
}

.menu-link.sidebar-active svg {
  font-weight: 600!important;
  color: white!important;
}

.macro-generator-btn,
.macro-generator-btn:hover,
.macro-generator-btn:focus{
  border-color: #2761f1;
  background-color: #2761f1!important;
  background: none;
  color: white;
  height: 46px;
  width: 100%;
  font-weight: 600;
  border-radius: 10px;
}

.sidebar .menu-items{
  display: flex;
  flex-direction: column;
  padding: 40px 0 40px 0;
  height: 100%;
  width: 80%; 
}

.sidebar .menu-items .menu-link{
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  cursor: pointer;
  color: black;
  font-size: 14px;
  font-weight: 500;
  /* letter-spacing: 0.3px; */
  padding: 14px 14px 14px 16px;
}

.sidebar .menu-items .menu-link:hover{
  border-radius: 10px;
  color: black;
  font-weight: 600;
} 


.sidebar .menu-items .menu-link svg{
  color: #dedede;
  margin-right: 15px;
  height: 16px;
}

.sidebar .menu-items .menu-link.sidebar-active svg{
  color: black!important;
}

/* .sidebar .menu-items .menu-link.sidebar-active:not(:first-child) svg{
  color: black!important;
} */

/* .sidebar .menu-items .menu-link.sidebar-active:first-child svg{
  color: #fba120!important;
} */

.sidebar .menu-items .menu-text{
  color: white;
  font-weight: 500;
  font-size: 14px;
  margin-left: 8px;
  font-family: 'Montserrat', sans-serif;
}

.sidebar .menu-items .button-link {
  border-radius: 10px;
  border: 1px solid white;
  background: none;
  color: white;
  font-weight: 500;
}

.macro-generator-btn.mobile {
  display: none;
}

.side-bar-sub-items {
  color: white;
  font-weight: 500;
  font-size: 14px;
}

@media screen and (max-width: 768px) {
  .ant-layout-sider {
    position: fixed;
    z-index: 99;
    height: 100%;
  }
  .macro-generator-btn.page-header {
    display: none;
  }
  .macro-generator-btn.mobile{
    display: block;
    background: #2761f1!important;
  }
}
