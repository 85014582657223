.workout-share {
  display: grid;
  align-items: center;
  justify-content: center;
  justify-items: center; /* Add this line for horizontal centering */
  width: 100%;
  background-color: white;
  height: 100vh;
  position: relative;
  overflow-y: auto;
  background: linear-gradient(-45deg, #2761f1 0%, #2bffcc 50%, #0065ff 100%);
}

.workout-share-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  border-radius: 10px;
  background-color: white;
  z-index: 999;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  padding: 30px;
  margin: 3vh 0;
}

/* .workout-share::before {
  content: '';
  position: absolute;
  top: 0; 
  left: 0;
  width: 100%;
  height: auto;
  background: linear-gradient(-45deg, #5400fc 0%, #2bffcc 50%, #0065ff 100%);
  z-index: 1;
} */

.workout-share-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
}

.workout-share-card {
  width: 100%; /* Set a fixed width for the card */
  max-width: 500px; /* Optionally set a maximum width */
  margin: 0 auto; /* Center the card horizontally */
  margin-bottom: 20px; /* Add margin at the bottom */
  padding: 10px;
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  text-align:center;
}

.workout-share-card:hover {
  transform: translateY(-2px);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

.three-column-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  width: 100%;
}

.killed-text {
  font-family: 'Creepster', cursive;
  font-size: 55px;
  color: #0065ff;
  text-transform: uppercase; 
  font-style: italic;
  line-height: 60px;
}

.bottom-div {
  margin-top: auto;
}

@media (max-width: 600px) {
  .workout-share-container {
    height: auto; 
    width: 90%;
  }
}