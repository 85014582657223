.meal-plan-library {
  display: flex;
  flex-direction: row;
  margin: 0 -5px;
}

.meal-plan-library .meal-plan-card {
  border-radius: 10px;
  padding: 24px;
  background: white;
  box-shadow: 0 5px 10px rgb(154 160 185 / 5%),
    0 15px 40px rgb(166 173 201 / 20%);
  width: 100%;
  margin: 0 5px;
}

.meal-plan-card .ant-divider {
  margin: 20px 0;
}

.target-values {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(4, 1fr);
  width: 100%;
  height: 40px;
  justify-items: end;
}

.target-values p {
  display: flex;
  flex-direction: column;
  margin-top: 6px;
  color: black;
  font-weight: 500;
}

.target-values p span {
  font-weight: normal;
}

.pb-4 {
  padding-bottom: 40px;
}