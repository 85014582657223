.workout-plan-card {
  display: flex;
  flex-direction: row;
  border-radius: 10px;
  background-color: white;
  overflow: hidden;
  box-shadow: 0 0 0 10px transparent, 0 0 0 12px linear-gradient(90deg, rgb(29, 205, 254), rgb(52, 245, 197));
}

.workout-plan-card .card-image {
  max-width: 300px;
  flex-shrink: 0;
}

.workout-plan-card .card-image img {
  width: 100%;
  height: 210px;
  background-size: 100% 100%;
  position: relative;
  border-radius: 10px 0 0 10px;
}

.workout-plan-card .card-content {
  padding: 20px;
  flex: 1;
}

.workout-plan-card .title-section {
  width: 100%;
  max-width: 90%;
}

.workout-plan-card .expanded-card-content {
  background: transparent;
}

/* Responsive styles */
@media (max-width: 768px) {
  .workout-plan-card {
    flex-direction: column;
  }

  .workout-plan-card .card-image {
    max-width: 100%;
  }

  .workout-plan-card .card-image img {
    width: 100%;
    height: 100%; /* Reduced height */
    border-radius: 10px 10px 0 0;
  }

  .workout-plan-card .card-content {
    padding: 20px;
  }
}

