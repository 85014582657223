.top-banner {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Montserrat', sans-serif;
  display: flex;
  /* background-color: #FF0066; */
  background: linear-gradient(to right, #2761f1 0%, #FF0066 100%);
  min-height: 45px;
  color: white;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  padding: 3px 20px;
  font-style: italic;
  letter-spacing: 0.5px;
}