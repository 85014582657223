.integration-card {
  border-radius: 10px;
  background: white;
  padding: 24px;
  box-shadow: 5px 5px 25px 0px rgba(46, 61, 73, 0.2);
  width: 100%;
  max-width: 1050px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 15px;
}

.integration-card .img-container {
  min-width: 200px;
  align-self: flex-start;
}

.integration-card .integration-content {
  width: 100%;
}

.integration-card .integration-content .title {
  font-weight: 600;
  font-size: 18px;
  line-height: 18px;
  color: #000000;
}

.integration-card .integration-content .description {
  font-size: 14px;
  line-height: 16px;
  color: black;
}

.integration-card .integration-content .button {
  margin-top: 30px;
}

.integration-card .integration-content .button.disabled-blue:disabled {
  background-color: #2761f1!important;
  color: white!important;
  cursor: auto;
}

.integration-content .title-container {
  margin-bottom: 5px;
}

.integration-content .integration-list {
  display: grid;
  grid-template-columns: 3% 97%;
  margin-bottom: 5px;
}


@media screen and (max-width: 768px) {
  .integrations {
    padding-bottom: 50px;
  }
  .integration-card .integration-content  {
    display: block;
  }
  .integration-card .img {
    margin-right: 0;
    height: 100px;
    margin-bottom: 15px;
  }
  .integration-content .integration-list {
    grid-template-columns: 10% 90%;
  }
}