.schedule-notification {
  padding: 20px;
  color: white;
  background-color: #FF0066;
  border-radius: 10px;
  font-weight: 500;
}

.schedule-notification .notification-content {
  display: flex;
  align-items: center;
}