.disclaimer {
  width: 100%;
  max-width: 1050px;
  margin-left: auto;
  margin-right: auto;
}

.disclaimer .title {
  margin-bottom: 0px;
}

.disclaimer .text {
 font-size: 14px;
 line-height: 24px;
}

@media screen and (max-width: 768px) {
  .disclaimer .title {
    font-size: 20px;
    line-height: 45px;
    margin-bottom: 0;
  }
  .disclaimer {
    width: 100%;
    max-width: 1050px;
    margin-left: auto;
    margin-right: auto;
  }
}