.review-container {
  display: flex;
  width: 100%;
  flex-wrap: wrap; /* Allow cards to wrap to the next row on smaller screens */
  justify-content: center; /* Center-align cards horizontally */
}

.review-card {
  display: grid;
  border-radius: 10px;
  height: 436px;
  text-align: center;
  box-shadow: 0 3px 25px 0 rgba(0, 0, 0, 0.05);
  flex: 1;
  margin: 16px;
  padding: 24px;
  border-radius: 10px;
  max-width: 380px; 
  min-width: 300px;
  width: 100%; 
  align-items: center;
  justify-items: center;
}

.star {
  font-size: 26px;
}

.star .filled-star {
  background: 'yellow';
} 

.review-card .link {
  font-size: 14px;
}