.workout-details-card {
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 5px 10px rgb(154 160 185 / 5%), 0 15px 40px rgb(166 173);
  background-color: #F5F5F5;
  margin-bottom: 20px;
  cursor: pointer;
}

.workout-details-card.notes {
  display: grid;
}

.workout-details-card .exercise-notes-columns {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 5px;
}

.workout-details-card .exercise-notes-columns :first-child {
  grid-column: 1 / -1;
}

.superset-code {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 40px;
  min-height: 40px;
  background-color: #f5f5f5;
  border-radius: 50%;
  cursor: pointer;
}

.workout-details .text {
  width: 100%;
  max-width: 500px;
}

.superset-code p {
  font-weight: 700;
  color: black;
}

.create-workout-modal .ant-input-group-addon {
  border-radius: 10px 0px 0px 10px;
  font-weight: 500;
  font-size: 11px;
  padding: 0 8px;
}

/* .set-input {
  margin-right: 5px;
} */

.exercise-name-isview {
  font-weight: 600;
  font-size: 16px;
  margin-top: 15px;
  margin-bottom: 0px;
}

.exercise-card .card-date {
  font-size: 14px;
  color: #FF0066;;
  font-weight: 400;
  margin-bottom: 3px;
}

.set-action-buttons {
  display: flex;
  justify-content: space-between;
  width: 100%;
  justify-content: center;
}

.add-exercise-button {
  display: flex;
  font-size: 28px; 
  margin-left: 3px;
  cursor: pointer;
  border-radius: 10px;
  background-color: #F5F5F5;
  height: 45px;
  width: 50px;
  margin-top: 3px;
}

.add-exercise-button .icon {
  align-self: center;
  margin: 0 auto;
  font-size: 22px;
}

.set-button {
  display: flex;
  justify-content: center;
  width: 50%;
  cursor: pointer;
  padding: 14px;
  border-radius: 10px;
  background: #f5f5f5;
  margin-right: 5px; /* add margin between buttons */
  align-items:center;
}

.set-button:last-child {
  margin-right: 0; /* remove margin from last button */
}

.sets .set-input.set.ant-input.ant-input-disabled{
  border: none;
  background: white;
  color: #000000;
  font-weight: 700;
  width: 50px;
  background: #f5f5f5;
  text-align: center;   
  cursor: auto;
}

.drag-icon {
  cursor: grab;
  font-size: 30px;
  user-select: none;
  display: flex;
  align-items: center;
  height: 100%;
}

.drag-icon.hidden {
  display: none;
}

.form-section .ant-checkbox-inner, .ant-checkbox-input {
  transform: scale(1.7);
  border-radius: 50%;
}

.floating-btn-container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  position: relative;
}

.floating-btn {
  position: fixed;
  z-index: 9999;
  top: 100px;
}

.floating-btn .circular-btn {
  width: 50px; /* Adjust the width and height as needed */
  height: 50px; /* Equal width and height make the button a circle */
  border-radius: 50%; /* Set the border-radius to 50% to create a circle */
  background-color: #2bffcc; /* Background color for the button */
  color: #fff; /* Text color */
  display: grid; /* Use CSS Grid for centering */
  place-items: center; /* Center both horizontally and vertically */
  line-height: 50px; /* Vertical alignment for the text */
  font-size: 16px; /* Adjust the font size as needed */
  cursor: pointer; /* Add cursor pointer for interactivity */
  border: none; /* Remove border for a clean circle */
  outline: none; /* Remove outline on focus (optional) */
}

.form-section .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #FF0066;
  border-color: #FF0066;
}

.form-section .ant-checkbox-checked .ant-checkbox-input {
  border-color: #2761f1;
}

.ant-input .ant-select-search__field {
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.create-workout-modal .ant-select-auto-complete.ant-select .ant-select-search--inline {
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.create-workout-modal .workout-pr-volume {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-content: space-between;
}

.create-workout-modal .workout-pr-volume .text {
 text-align: center;
}

.create-workout-modal .workout-details-card .text {
  font-weight: 600;
  font-style: italic;
  text-transform: uppercase;
  color: black;
}

@media screen and (max-width: 768px) {
  .set-input {
    margin-right: 3px;
  }
  .set-input.set {
    width: 40px;
  }
  .create-workout-modal .ant-modal-body {
    padding: 15px 10px 10px 10px;
  }
  .workout-details .text {
    width: 100%;
    max-width: 220px;
  }
  .exercise-notes-title.home-card-text {
    display: none;
  }
  .create-workout-modal .workout-pr-volume {
    display: grid;
    grid-template-columns: 1fr;
  }
  .create-workout-modal .workout-pr-volume .text {
    text-align: left;
   }
}