.main-page-title-container {
  width: 100%;
  max-width: 1050px;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 30px;
  margin-top: 30px;
  border-radius: 10px;
  padding: 20px;
  background: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.main-page-title-container .title-section-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.main-page-title-container.no-margin {
  margin-top: 0px;
  border: none;
  padding: 0px;
  display: none;
}

.main-page-title-container.admin {
 display: none;
}

.main-page-title {
  font-size: 24px;
  font-weight: 800;
  line-height: 34px;
  color: #000000;
  font-style: italic;
  text-transform: uppercase;
}

.main-page-subtitle {
  width: 100%;
  max-width: 600px;
  font-size: 16px;
  line-height: 22px;
  color: #5c5c5c;
}

.title-header-btn-container {
  display: flex;
  grid-gap: 5px;
  align-items: center;
}

@media screen and (max-width: 768px) {
  .main-page-title-container .title-section-content {
    display: grid;
    grid-gap: 10px;
  }
  .main-page-title {
    font-size: 24px;
    line-height: 32px;
    color: #000000;
  }
  .title-header-btn-container {
    display: grid;
  }
  .title-header-btn-container .item-0 { order: 1; }
  .title-header-btn-container .item-1 { order: 0; }
}