.week-buttons {
  margin-top: 20px;
}

.week-buttons button {
  margin-right: 3px;
  text-transform: capitalize;
  border-radius: 10px;
  font-weight: 700;
  height: 46px;
  color: #5c5c5c;
  border-color: white;
}

.week-buttons .not-active-day:hover {
 border-color: #d3d3d3;
 box-shadow: 0 5px 10px rgb(154 160 185 / 5%), 0 15px 40px rgb(166 173 201 / 20%);
}


.week-buttons .day-button {
  border: 1px solid #d3d3d3;
  margin-right: 5px;
  font-weight: 600;
  font-size: 14px;
  padding: 7px 15px;
  border-radius: 10px;
  cursor: pointer;
  color: #000000;
  min-width: 130px;
  text-align: center;
}

.week-buttons .day-button:hover{
  background: white;
  transition: all 0.3s ease-out;
}

.week-buttons .day-button.day-selected {
  border: 1px solid #2761f1;
  background: #2761f1;
  color: white;
  font-weight: 600;
}

.week-buttons .ant-btn-primary, .save-btn-modal {
  color: white;
  text-transform: capitalize;
}

.week-setup {
  margin-top: 20px;
}

.plus {
  margin-right: 10px;
  transform: scale(1.5);
}

.muted {
  color: rgba(0,0,0,0.3);
}

.addMealBtn {
  margin-top: 10px;
}

.ml-auto {
  margin-left: auto;
}

.mb-2 {
  margin-bottom: 10px;
}

.mb-3 {
  margin-bottom: 20px;
}

.mb-4 {
  margin-bottom: 30px;
}

.mb-5 {
  margin-bottom: 50px;
}

.mt-1, .my-1 {
  margin-top: 5px;
}

.mb-1, .my-1 {
  margin-bottom: 5px;
}

.mt-2 {
  margin-top: 10px;
}

.mt-3 {
  margin-top: 20px;
}

.mt-4 {
  margin-top: 30px;
}

.mr-1, .mx-1 {
  margin-right: 10px;
}

.ml-1, .mx-1 {
  margin-left: 10px;
}

.mr-1 {
  margin-right: 10px;
}

.mr-2 {
  margin-right: 20px;
}

.mr-3 {
  margin-right: 30px;
}

.mr-05 {
  margin-right: 5px;
}

.py-2.ant-btn {
  height: 40px;
}

.pointer {
  cursor: pointer;
}

.cross {
  position: absolute;
  right: 0;
  top: 3px;
  line-height: 24px;
  font-size: 32px;
  cursor: pointer;
  margin-right: 5px;
  user-select: none;
  transition: .3s;
}

.cross:hover {
  color: red;
}

.font-weight-bold {
  font-weight: 500;
}

.mealplan-title {
  max-width: 200px;
}

.text-right {
  text-align: right;
}

.blue-link {
  color: #0074FF;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
}

.fsize-14 {
  font-size: 14px;
}

.week-item {
  border-top: 1px solid rgba(0,0,0,0.09);
  border-radius: 10px;
  background-color: #fff;
  padding: 15px 20px;
  margin-top: 5px;
  position: relative;
}
.week-item:first-child{
  border-top: 0px solid rgba(0,0,0,0.09);
}

.week-item-info {
  position: relative;
  border-radius: 10px;
  background-color: #f0f0f0;
  padding: 15px;
  margin-bottom: 5px;
}

.flex-column-mobile {
  flex-direction: column;
}

.top-line {
  border-top: 1px solid rgba(0,0,0,0.1) !important;
  margin-top: 10px;
  padding-top: 10px;
}

.ant-modal-title {
  text-transform: capitalize;
}

.week-buttons-flex {
  overflow-x: auto;
}

@media screen and (min-width: 1400px) {
  .week-buttons-flex .day-button {
    width: 100%;
  }
}

@media screen and (min-width: 992px) {
  .week-buttons-flex .day-button {
    width: 100%;
    min-width: 100px;
  }
  .flex-column-mobile {
    flex-direction: row;
  }
  .flex-lg {
    display: flex;
  }
  .total-width {
    width: 702px;
  }
  .input-food {
    min-width: 400px;
  }
}

@media screen and (max-width: 992px) {
  .mt-mobile-2 {
    margin-top: 10px;
  }
  .input-food {
    min-width: 100%;
  }
}

@media screen and (max-width: 512px) {
  .input-food + div {
    width: 100%;
    margin-top: 5px;
    margin-bottom: 10px;
  }
  .week-item {
    padding: 10px;
  }
}

.select-area {
  display: flex;
  flex-wrap: wrap;
}

.food-icon {
  display: flex;
  padding: 5px;
  cursor: pointer;
  border-radius: 15px;
  background-color: #fff;
  transition: .3s;
}

.food-icon-active {
  background-color: #0065ff;
  color: #fff;
}

/* .week-item-info .ant-select-selection--single.ant-select-selection--single, .custom-food-modal .ant-select-selection--single.ant-select-selection--single {
  height: 100%;
  border-radius: 4px;
  width: 100%;
}

.week-item-info .ant-select-selection--single .ant-select-selection__rendered, .custom-food-modal .ant-select-selection--single .ant-select-selection__rendered {
  margin-top: 0;
  height: 30px;
}

.week-item-info .ant-select-selection-selected-value, .custom-food-modal .ant-select-selection-selected-value {
  line-height: 30px;
} */

.ant-select-selection-selected-value div div:nth-child(2), .ant-select-selection__choice__content div div:nth-child(2), .ant-select-selection-selected-value .option-image {
  display: none;
}

.input-food .ant-select-selection-selected-value {
  text-transform: capitalize;
}

.input-food-image .ant-select-selection__rendered {
  padding-left: 34px;
}

.option-image {
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(8px, 8px);
  width: 30px;
  height: 30px;
  border-radius: 50%;
  object-fit: cover;
  z-index: 500;
}

.option-image-select {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 5px;
}

.position-relative {
  position: relative;
}

.option-image-absolute {
  position: absolute;
  z-index: 9;
  margin-left: 5px;
}

.option-image-absolute + .ant-select .ant-select-selection__rendered {
  margin-left: 20px;
}

.input-serving {
  width: 70px;
}

.input-food {
  width: 100%;
  padding-bottom: 10px;
}

.input-serving-label {
  width: 160px;
}

.w-100 {
  width: 100%;
}

.target-values p {
  display: flex;
  flex-direction: column;
  margin-top: 6px;
  color: black;
  font-weight: 500;
}

.target-values p span {
  font-weight: normal;
}

.input-group .ant-input, .input-serving-label .ant-select-selection {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.week-buttons-flex::-webkit-scrollbar {
  display: none;
}
