.dashboard-content .home-page,
.dashboard-content .nutrition-preferences,
.dashboard-content .meal-plan-library,
.dashboard-content .meal-plan-page,
.dashboard-content .program-page,
.dashboard-content .macros-page,
.dashboard-content .recipe-page,
.dashboard-content .learn-page,
.dashboard-content .exercises-page,
.dashboard-content .workouts-page,
.dashboard-content .tools-page {
  width: 100%;
  max-width: 1050px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
}

.recipe-page .meal-plan {
  margin-top: 0px;
}

.recipe-page .daily-meals-wrapper {
  margin-top: 0px;
}

.home-page .remove-confetti{
  display: none;
}

.dashboard-content .home-page .home-card {
  border-radius: 10px;
  padding: 24px;
  background: white;
  box-shadow: 0 5px 10px rgba(154,160,185,.05), 0 15px 40px rgba(166,173,201,.2);
}

.dashboard-content .home-page .home-card a span {
  color: #2761f1 !important;
}
.home-page .no-data {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 400px;
  justify-content: center;
}

.home-page .home-card.smart-coach {
  height: 100%;
  display: grid;
  align-items: center;
  grid-gap: 20px;
  margin-bottom: 20px;
}

.home-page .home-card.smart-coach .img{
  height: 200px;
  justify-self: center;
}

.home-page .home-card.smart-coach .title,
.meal-plan-page .title {
  font-size: 18px;
  line-height: 22px;
  font-weight: 500;
  margin-bottom: 5px;
  color: #040926;
}

.home-page .home-card.smart-coach .card-subtitle {
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 15px;
}

.home-page.loading {
  flex-direction: column;
  justify-content: center;
}

.dashboard-content .home-page .half-card-container {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(auto-fit, minmax(330px, 1fr));
  grid-auto-rows: 1fr;
}

/* .dashboard-content .home-page .half-card-container .img {
  border-radius: 5px;
  height: 220px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
} */

.dashboard-content .home-page .half-card-container.no-margin {
  margin-bottom: 0px;
}

.dashboard-content .home-card-title-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #ECEBE7;
}

.dashboard-content .home-card-title {
  font-weight: 500;
  font-size: 13px!important;
  color: #000000;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.dashboard-content .home-card-title.small {
  font-size: 10px!important;
  margin-bottom: 0px;
}

.dashboard-content .macros-card {
  background: white;
  border-radius: 10px;
  margin-bottom: 20px;
  box-shadow: 5px 5px 25px 0px rgba(46, 61, 73, 0.2);
}

.dashboard-content .macros-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: 1fr;
  align-content: center;
  border-radius: 10px;
  margin-top: 5px;
  grid-gap: 120px;
  align-items: center;
  /* box-shadow: 0 5px 10px rgba(154,160,185,.05), 0 15px 40px rgba(166,173,201,.2); */
}

/* .dashboard-content .macros-container .home-macro-card:not(:last-child) {
  border-right: 1px solid #f0f0f0;
} */

.dashboard-content .macros-container.progress-padding {
  grid-template-columns: repeat(4, 1fr);
  padding: 20px;  
  background: white;
  border-radius: 10px;
}

.dashboard-content .macros-container.no-grid-gap {
  grid-gap: 0px;
  box-shadow: 0 5px 10px rgb(154 160 185 / 5%), 0 15px 40px rgb(166 173 201 / 20%);
}

.macros-container.bottom-margin {
  margin-bottom: 30px;
}

.dashboard-content .macro-number {
  font-weight: 500;
  font-size: 25px; 
  line-height: 30px; 
  line-height: 25px;
  color: black;
}

.dashboard-content .macro-number.green,
.text-suffix.green {
  /* color: #42ca49; */
  color: #2bffcc;
}

.dashboard-content .macro-number.red,
.text-suffix.red {
  color: red;
}

.dashboard-content .home-macros {
  /* background: white; */
  border-radius: 10px;
  margin-bottom: 40px;
  /* background: white; */
}

.dashboard-content .home-macros .ant-divider {
 margin: 15px 0;
}

.dashboard-content .fitbit-macros-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 120px;
  grid-auto-rows: 1fr;
  margin-top: 5px; 
}

.dashboard-content .fitbit-macros-container .icon {
  justify-self: center;
}

.home-macros .grid-list {
  display: flex;
}

.home-macros .home-macros-card {
  padding: 20px;
  border-radius: 10px;
  background: white;
  margin-bottom: 10px;
  box-shadow: 0 5px 10px rgba(154,160,185,.05), 0 15px 40px rgba(166,173,201,.2);
}

.home-macros .home-macros-card.fitbit {
  cursor: pointer;
}

.dashboard-content .macro-label-bottom {
  font-size: 14px;
  line-height: 12px;
  color: #000000;
  margin-top: 3px;
}

.dashboard-content .blog-card-category.macro-label-bottom.gray{
  color: #5c5c5c!important;
}

.dashboard-content .macros-card .home-card-menu-dots {
  cursor: pointer;
}

.dashboard-content .goal-card,
.dashboard-content .diet-card,
.dashboard-content .weight-card {
  width: 100%;
  border-radius: 10px;
  padding: 20px;
  background: white;
  box-shadow: 0 5px 10px rgba(154,160,185,.05), 0 15px 40px rgba(166,173,201,.2);
}

.margin-bottom {
  margin-bottom: 20px;
}

.dashboard-content .goal-card .home-card-text {
  line-height: 20px;
}

.dashboard-content .goal-card .goal {
  width: 100%;
}

.dashboard-content .goal-card.color {
  background-color: #78797d;
  color: white;
  box-shadow: 0 5px 10px rgba(154,160,185,.05), 0 15px 40px rgba(166,173,201,.2);
}

.dashboard-content .half-card-container .diet-card {
  min-height: 210px;
}

.diet-card .ant-divider-horizontal {
  margin: 17px 0;
}

.diet-card .step:not(:last-child) {
  padding-bottom: 5px;
}

.diet-card .step svg {
  font-size: 20px;
  margin-right: 5px;
}

.diet-card .steps-title {
  font-size: 14px;
  margin-bottom: 10px;
  font-weight: 500;
  color: black;
  line-height: 18px;
}

.diet-card .step .text {
  font-size: 15px;
  font-weight: 500;
  color: black;
  margin-right: 15px;
}

.diet-card .step.completed svg,
.diet-card .step.completed .text{
  color: green;
}

.dashboard-content .shopping-list-card {
  display: flex;
  flex-direction: column;
  overflow: auto;
  width: 100%;
  height: 250px;
  border-radius: 10px;
  padding: 20px;
  background: white;
  box-shadow: 5px 5px 25px 0px rgba(46, 61, 73, 0.2);
  margin-bottom: 20px;
}

.dashboard-content .weight-card {
  height: 325px;
}

.dashboard-content .workouts-card .event-card .body-part {
  font-size: 16px;
  font-weight: 500;
  color: black;
}

.home-page .weight-card {
  width: 100%;
  margin-bottom: 40px;
}

.home-page .chartjs-render-monitor {
  padding-bottom: 30px;
  height: auto!important;
  padding: 24px 24px 24px 18px;
  background: white;
  border-radius: 10px;
  height: 350px!important;
  margin-bottom: 30px;
}

.home-page .weight-and-workout-section {
 height: 400px;
}

.home-card-text {
  line-height: 16px;
  font-size: 16px;
  font-weight: 500;
  color: #000000;
}

.dashboard-content .home-stats {
  display: grid;
  grid-template-columns: 100%;
  grid-gap: 10px;
}

.dashboard-content .home-stats .arrow {
  /* color: #5400fc; */
  color: #2bffcc;
  height: 15px;
  width: 15px;
  margin-left: -5px;
}

.dashboard-content .home-stats .difference-text{
  font-size: 12px;
  font-weight: 600;
  line-height: 12px;
}

.dashboard-content .home-stats .home-card,
.client-card-container .home-card,
.exercise-card {
  background: white;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 5px 10px rgba(154,160,185,.05), 0 15px 40px rgba(166,173,201,.2);
  // // box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  // // box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15);
  // box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);


}

.dashboard-content .card-main-text {
  font-weight: 500;
  font-size: 25px; 
  line-height: 30px; 
  line-height: 25px;
  color:#000000;
}

.add-measurement-btn,
.add-measurement-btn:hover {
  background: #2761f1;
  border: none;
  cursor: pointer;
}

.add-measurement-btn .plus-icon {
  color: white;
}

.ant-btn.add-measurement-btn:hover, .ant-btn.add-measurement-btn:focus, .ant-btn.add-measurement-btn:active, .ant-btn.add-measurement-btn.active {
  background: #2761f1;
}

.ant-btn.add-measurement-btn:disabled {
  background: #e4e7eb;
}

.suggestions .add-measurement-btn.add-type-btn {
  margin-bottom: 20px;
}

.suggestions .meal-type-selection {
  padding: 3px 8px;
  margin: 0 5px 5px 0;
  border-radius: 10px;
  background: rgb(228, 231, 235);
}

.suggestions .meal-type-selection svg {
  color: black;
  margin-left: 3px;
  cursor: pointer;
}


.suggestions .meal-type-selection p{
  font-size: 14px;
  font-weight: 400;
}

.suggestions .add-btn {
  margin-bottom: 10px;
}

.home-card.smart-coach .text-content {
  text-align: center;
}

.summary-card.program-card {
  cursor: pointer;
}

.todo-list {
  display: grid;
  grid-template-columns: 3% 97%;
}

.home-page-program-cards {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
}

@media screen and (max-width: 768px) {
  .home-page .weight-and-workout-section {
    display: block;
  }
  .home-page .weight-card {
    display: block;
  }
  .dashboard-content .macro-number {
    font-size: 18px; 
    line-height: 16px;
  }
  .dashboard-content .card-main-text {
    font-size: 22px; 
  }
  .dashboard-content .macros-container,
  .dashboard-content .fitbit-macros-container {
    grid-gap: 15px;
    /* box-shadow: 0 5px 10px rgba(154,160,185,.05), 0 15px 40px rgba(166,173,201,.2); */
  }
  .home-page .home-card.smart-coach {
    height: auto;
  }
  .dashboard-content .macros-container.progress-padding {
    padding: 20px 0px;  
    background: white;
  }
  .title-meal-plan {
    font-weight: 20px;
  }
  .dashboard-content .macro-label-bottom {
    font-size: 14px;
    line-height: 16px;
    max-width: 240px;
  }
  .todo-list {
    display: grid;
    grid-template-columns:repeat(12, 1fr);
  }
  .todo-list svg {
    grid-column: 0/1;
  }
  .todo-list .list-text {
    grid-column: 2/12;
  }
  .list-text {
    justify-content: space-between;
  }
}

$img-url: 'https://images.unsplash.com/photo-1543352634-a1c51d9f1fa7?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1650&q=80';

$img-url2: 'https://images.pexels.com/photos/841130/pexels-photo-841130.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2';

.diet-card.card-2 {
  height: 210px;
  background-image: url($img-url);
  background-size: 100% 100%;
  position:relative;
}

.diet-card.card-2.workout {
  height: 210px;
  background-image: url($img-url2);
  background-size: 100% 100%;
  position:relative;
}

.overlay {
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
  border-radius: 10px;
  box-shadow: 0 5px 10px rgb(154 160 185 / 5%), 0 15px 40px rgb(166 173 201 / 20%);
}

.diet-card.card-2:hover {
  cursor: pointer;
}

.diet-card.card-2:hover .meal-plan-text{
  cursor: pointer;
  bottom: 15px!important;
  transition: bottom 0.2s ease-out!important;
}

.diet-card.card-2 .meal-plan-text {
  font-size: 30px;
  color: white;
  position:absolute;
  bottom:10px;
  right:0;
  right: 25px;
  font-weight: 600;
  text-transform: uppercase;
  font-style: italic;
  line-height: 40px;
}
