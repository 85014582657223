.dashboard-content .admin-home-page {
  width: 100%;
  max-width: 1150px;
  margin-left: auto;
  margin-right: auto;
}

.clients-table .table-count {
  margin: 0 0 5px 5px;
  font-weight: 500;
  font-size: 16px;
}

.clients-table .days-left {
  color: white;
  font-weight: 600;
  padding: 5px 5px;
  border-radius: 500px;
  text-align: center;
  width: 80px;
}

.capsule-red {
  background-color: #FF0066;
}

.capsule-green {
  background-color: #2bffcc;
}

.clients-table .client-card-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 10px;
  grid-auto-rows: 1fr;
  margin-bottom: 20px;
}