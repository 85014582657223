.macros-page {
  margin-bottom: 50px;
}

.macro-history-container{
  display: grid;
  grid-gap: 10px;
}

.macro-history-card {
  background: white;
  padding: 24px;
  border-radius: 10px;
}

.macro-history-card .date {
  margin-bottom: 10px;
}

.macro-history-card .macro-section {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  justify-content: space-between;
}

@media screen and (max-width: 768px) {
  .macro-history-card .macro-section .text-block{
    display: flex;
    justify-content: space-between;
  }
  .macro-history-card .macro-section {
    display: block;
  }
}