.two-columns {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
  grid-auto-rows: 450px; /* Set the height of each row */
  margin-bottom: 20px;
  background: #f0f0f0;
  border-radius: 10px;
  padding: 10px;
}

.two-columns-text {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
}

.column-container {
  height: 100%;
  overflow-y: auto; 
  box-sizing: border-box; 
}