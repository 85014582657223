.exercise-name-history {
  font-size: 18px;
  font-weight: 800;
  color: black;
  text-transform: uppercase;
  font-style: italic;
  text-align: center;
  margin: 0 auto;
  margin-bottom: 20px;
}

.history-info-item {
  display: grid;
  justify-items: center;
}

.history-info-container .history-info {
  padding: 10px 0;
  display: flex;
  justify-content: space-around;
}

.gradient-text{
  cursor: pointer;
  font-weight: 500;
}

.gradient-text p {
  /* background: linear-gradient(to right, rgb(29, 205, 254) 0%, rgb(52, 245, 197) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */
  color: #ff0066;
}

.gradient-text .icon {
  /* background: linear-gradient(to right, rgb(29, 205, 254) 0%, rgb(52, 245, 197) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */
  color: #ff0066;
  margin-right: 3px;
  font-size: 20px;
}

.big-bold-text {
  font-size: 28px;
  line-height: 35px;;
  font-weight: 700;
  color: #000000;
}

.set-history-container {
  margin-top: 20px;
}

.set-history-date {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
  color: black;
}

.set-history {
  border-collapse: collapse;
  margin-bottom: 20px;
  width: 100%;
}

.set-history th,
.set-history td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
}

.set-history th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.set-history tr:nth-child(even) {
  background-color: #f2f2f2;
}

.exercise-history-tabs .exercise-history-chart {
  margin-top: 30px;
}

.exercise-history-tabs .ant-tabs-nav-scroll{
  display: flex;
  justify-content: center;
}

.ant-table-tbody tr:hover > td {
  background-color: transparent !important;
}

@media screen and (max-width: 768px) {
  .history-info-container .history-info {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding: 0px;
  }
  .history-info-item {
    margin-bottom: 15px;
  }
  .blog-card-category {
    margin-bottom: 5px;
  }
  .exercise-name-history {
    width: 100%;
    max-width: 300px;
    text-align: center;
    margin: 0 auto;
  }
}