/* .week-item-client .ant-select-selection--single.ant-select-selection--single, .custom-food-modal .ant-select-selection--single.ant-select-selection--single {
  height: 100%;
  border-radius: 4px;
  width: 100%;
}

.week-item-client .ant-select-selection--single .ant-select-selection__rendered, .custom-food-modal .ant-select-selection--single .ant-select-selection__rendered {
  margin-top: 0;
  height: 30px;
}

.week-item-client .ant-select-selection-selected-value, .custom-food-modal .ant-select-selection-selected-value {
  line-height: 30px;
} */

.meal-item-row {
  display: block;
  margin-bottom: 18px;
}

.mb-0 {
  margin-bottom: 0;
}

.week-item-title {
  line-height: 32px;
  position: relative;
}

.image-standard {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.image-standard-modal {
  width: 100%;
  height: 100%;
}

.food-image {
  width: 80px;
  height: 80px;
  position: relative;
  margin: 10px;
}

.edit-button {
  line-height: 24px;
  font-size: 32px;
  cursor: pointer;
}

.delete-button {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
  line-height: 24px;
  font-size: 32px;
  cursor: pointer;
  user-select: none;
  transition: .3s;
}

.input-food-image .ant-select-selection__rendered {
  padding-left: 34px;
}

.option-image {
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(8px, 8px);
  width: 30px;
  height: 30px;
  border-radius: 50%;
  object-fit: cover;
  z-index: 500;
}

.option-image-select {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 5px;
}
.total-main-wrap{
  border-top: 1px solid rgba(0,0,0,0.09);
  margin: 10px 0 10px 0;
  padding-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.done-text{
  border-left: 2px solid #595959;
  margin-left: 10px;
  padding-left: 10px;
}
.icon-wrap{
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.week-item-wrap .ant-divider-horizontal:first-child{
 display: none;
}
.food-item-wrap{
  display: flex;
  align-items: center;
}
.food-item-wrap .food-icon{
  font-size: 15px;
}
.week-setup .ant-collapse-item .ant-collapse-header .ant-collapse-arrow{
  right: 16px;
  left: unset;
}
.week-setup .ant-collapse .ant-collapse-item {
  background-color: white;
  border: none;
  margin-bottom: 10px;
  border-radius: 10px;
}
.week-setup .ant-collapse {
  background-color: transparent;
  border: none;
}
.week-setup .ant-collapse-content-active {
  border-radius: 0px 0px 10px 10px;
}
.collapsed-content-wrap{
  display: flex;
  align-items: center;
}
.meal-title-wrap{
  display: flex;
  flex-direction: column;
}

.food-img-modal .ant-modal-body {
  padding: 0px!important;
}

.nutrition-facts-modal.ant-divider:not(:last-child) {
  margin: 10px 0;
}

.nutrition-facts-modal.ant-divider:last-child {
  display: none;
}

@media screen and (max-width: 768px) {
  .total-main-wrap{
    display: grid;
  }
  .meal-title-wrap {
    width: 100%;
  }
  .blue-link {
    line-height: 14px;
  }
  .meal-item-row .food-nutrition-info {
    width: 80%;
  }
  .food-item-wrap {
    margin-bottom: 5px;
  }
  .food-item-wrap .home-card-text{
    line-height: 18px;
  }
}