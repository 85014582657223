.content.blog-page {
  padding: 100px 30px 30px 30px;
}

.blog-page {
  width: 100%;
  max-width: 1250px;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  justify-content: space-between;
}

.blog-page .blog-grid {
  display: grid;
  width: 60%;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 10px;
  grid-template-rows: max-content;
}

.blog-page .blog-sidebar {
  display: grid;
  width: 40%;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(12, 1fr);
  height: 100vh;
  grid-gap: 5px;
}

.blog-page .blog-card-horizontal {
  grid-column: 1/13;
  border-radius: 10px;
  border: 1px solid #f2f2f2;
  cursor: pointer;
  margin-bottom: 30px;
  transition: box-shadow .3s;
  box-shadow: 5px 5px 25px 0px rgba(46, 61, 73, 0.2);
  /* height: 100%;
  max-height: 360px; */
}

.blog-page .blog-card-horizontal .card-content {
  height: auto;
}

.blog-page .blog-card-horizontal .card-content .text-content .blog-title{
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 500;
}

.blog-page .blog-card-horizontal:hover{
  box-shadow: 2px 4px 8px 0px rgba(46, 61, 73, 0.2);
}

.blog-page .blog-container .blog-sidebar {
  height: 100%;
  background: #f0f0f0;
}

.blog-page .blog-card-horizontal .text-content {
  padding: 22px;
}

.blog-page .blog-card-horizontal .image-card {
  height: 220px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border-radius: 10px 10px 0 0;
}

@media screen and (max-width: 768px) {
  .blog-page .blog-grid {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .blog-page .blog-sidebar {
    display: grid;
    width: auto;
    grid-template-columns: none;
    grid-template-rows: none;
    height: auto;
    grid-gap: 0px;
    align-items: center;
  }
  .blog-page {
    display: block;
  }
  .blog-page .blog-card-horizontal {
  }
}