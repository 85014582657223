.pricing-modal .pricing-modal-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 15px;
  padding: 20px;
}

.pricing-modal .pricing-modal-container .pricing-card {
  padding: 20px;
  border-radius: 10px;
  background: white;
  border: 1px solid #f0f0f0;
}

.pricing-modal .pricing-modal-container .pricing-card .plan-name {
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: 700;
  border-radius: 50px;
}

.pricing-modal .pricing-modal-container .pricing-card .plan-name.free{
  color: #0065ff;
}

.pricing-modal .pricing-modal-container .pricing-card .plan-name.pro{
  color: #2bffcc;
}

.pricing-modal .pricing-modal-container .pricing-card .plan-name.pro-coach { 
  color: #FF0066;
}

.pricing-modal .pricing-modal-container .pricing-card .plan-amount {
  font-size: 30px;
  font-weight: 500;
  line-height: 35px;
}

.pricing-modal .pricing-modal-container .pricing-card .pricing {
  margin-bottom: 20px;
  color: black;
}

.pricing-modal .pricing-modal-container .pricing-card .plan-billed {
  align-self: flex-end;
}

.pricing-modal .pricing-modal-container .pricing-card .plan-includes {
  margin-bottom: 20px;
  margin-top: 20px;
}

.pricing-modal .pricing-modal-container .pricing-card .plan-include-item {
  color: black;
  line-height: 20px;
  margin-bottom: 15px
}

.plan-includes .list {
  display: grid;
  grid-template-columns: 10% 90%;
}

.pricing-modal .pricing-modal-container .pricing-card .plan-button {
  margin-top: auto;
}

.pricing-modal .pricing-modal-container .pricing-card .plan-button.pro-coach {
  background: #FF0066;
}

.pricing-modal .pricing-modal-container .pricing-card .plan-button.pro {
  background: #2bffcc;
}

@media screen and (max-width: 768px) {
  .pricing-modal .pricing-modal-container {
    grid-template-columns: none;
    padding: 0px;;
  }
}