.upload-photo {
  opacity: 0;
  position: absolute;
  z-index: -1;
}

.progress-upload-label {
  background-color: #2761f1;
  border: 1px solid #2761f1;
  color: white;
  font-weight: 500;
  border-radius: 10px;
  padding: 5.5px 10px;
  font-size: 14px;
  margin-right: 10px;
  cursor: pointer;
  transition: 300ms;
}

.progress-upload-label, .progress-upload-label + span {
  user-select: none;
}

.progress-upload-label + span {
  margin-left: 5px;
  margin-right: 15px;
}

img.user-photo {
  margin-top: 15px;
  margin-bottom: 10px;
}

video {
  margin-top: 15px;
  width: 100%;
}

.decline-btn {
  margin-left: 10px;
}

.selected-preview {
  margin-top: 10px;
}

.selected-preview-images {
  display: flex;
  flex-wrap: wrap;
  margin-top: 5px;
}

.selected-preview-images .preview-inner-box {
  position: relative;
  width: 80px;
  height: 120px;
  border: 1px solid rgba(0,0,0,0.1);
}

.selected-preview-images .preview-outer-box {
  padding-bottom: 5px;
}

.selected-preview-images .preview-outer-box h6 {
  margin-bottom: 0;
  text-align: center;
}

.selected-preview-images div span {
  position: absolute;
  right: 0;
  padding: 0 5px;
  font-size: 12px;
  opacity: 0.5;
  cursor: pointer;
  transition: 300ms;
  background-color: #fff;
}

.selected-preview-images .arrow {
  align-self: center;
  margin-bottom: 20px;
  padding: 0 5px;
}

.selected-preview-images div span:hover {
  opacity: 1;
}

.selected-preview-images div img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media screen and (max-width: 992px) {
  .toggle-webcam {
    display: none;
  }
}