.search .recipe-search {
  margin-right: 5px;
}

.search .recipe-search .ant-btn-ghost{
  margin-right: 5px;
}

.filter-menu .filter-container .filter-section{
  margin-bottom: 20px;
}

.filter-menu .filter-container .filter-section p { 
  padding-bottom: 10px;
  color: #000000;
  font-size: 14px;
  font-weight: 600;
}

.filter-menu .filter-btn-container {
  display: flex;
  justify-content: flex-end;
}

.filter-menu .filter-btn-container .ant-btn-primary {
  width: 80px;
}

.filter-menu .check-box-filters {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column: 1/2;
}

.filter-menu .calorie-filter {
  display: flex;
  justify-content: space-between;
  grid-template-columns: repeat(1, 1fr);
}

.filter-menu .calorie-filter .ant-input {
  height: 46px;
  border-radius: 10px;
  width: 49%;
}

.filter-menu .filter-container .ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin-left: 0;
}

.recipe-card-container {
  display: grid;
  grid-gap: 10px;
  margin-top: 30px;
  justify-content: center;
}

.recipe-card-container .recipe-card {
  width: 100%;
  min-width: 340px;
  border-radius: 10px;
  transition: box-shadow .3s;
  box-shadow: 0 5px 10px rgb(154 160 185 / 5%), 0 15px 40px rgb(166 173 201 / 20%);
  background: white;
  cursor: pointer;
}

.recipe-card-container .recipe-card .img {
  border-radius: 10px 10px 0 0;
  height: 200px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.recipe-card-container .recipe-card .bottom-section {
  padding: 10px 30px 0px;
  background: white;
  border-radius: 0 0 10px 10px;
}

.recipe-card-container .recipe-card .bottom-section .serving-info {
  margin: 10px 0 10px 0;
}

.recipe-content .ant-divider {
  margin: 15px 0;
}

.recipe-card-container .recipe-card .bottom-section .label-group {
  margin: 5px 0 5px 0;
}

.label-group .labels {
  padding: 2px 8px;
  font-weight: 600;
  font-size: 10.5px!important;
  color: white;
  letter-spacing: .75px;
  margin-bottom: 2px;
  margin-top: 5px;
  text-transform: uppercase;
  background: #FF0066;
   /* background: linear-gradient(to right, #2bffcc 0, #4D2BFF 41.67%, #FF0066 100%); */
  border-radius: 10px;
  margin-right: 2px;
  border: 1px solid 
}

.recipe-card-container .recipe-card .bottom-section .recipe-card-title {
  font-size: 20px;
  font-weight: 600;
  color: #000000;
  line-height: 26px;
}

.recipe-card-container .recipe-card .bottom-section .macro-label {
  font-weight: 500;
  font-size: 12px!important;
  color: black;
  letter-spacing: .75px;
  text-transform: uppercase;
  margin-bottom: -5px;
}

.recipe-pagination {
  margin-top: 20px;
  margin-bottom: 50px;
}

.recipe-search-card {
  margin-bottom: 25px;
}

#edamam-badge {
  margin: 10px 0 20px 0px;
}

.grocery-card {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 5px 5px 25px 0px rgba(46, 61, 73, 0.2);
  margin-bottom: 15px;
  cursor: pointer;
}

.grocery-card.reduce-padding {
  padding: 10px;
}

.grocery-card .image {
  height: 60px;
  border-radius: 10px;
  margin-right: 20px;
}

.grocery-card .collapsed-content {
  padding: 20px;
}

.grocery-card .title {
  width: 100%;
  max-width: 800px;
  font-weight: 500;
  color: black;
  line-height: 16px;
  font-size: 15px;
  margin-bottom: 5px;
}

.grocery-card .icon {
  font-size: 20px;
  color: red;
  cursor: pointer;
}

.recipe-info.grocery-card-expanded {
  margin-top: 10px;
}

.recipe-info.grocery-card-expanded .button-section {
  margin-top: 20px;
}

.recipe-search {
  display: flex;
}

.recipe-search .ant-input-group-addon {
  background: #2761f1;
}

.recipe-img {
  height: 220px;
}

.recipe-search-bar {
  width: 100%;
  max-width: 600px;
}

.recipe-search-bar .title {
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0.75px;
  color: black;
  text-transform: uppercase;
}

.dashboard-content .no-site-layout-container {
  margin-top: 0px;
}

.dashboard-content .new-shopping-list-btn {
  /* top: 135px;
  position: absolute; */
}

.new-shopping-list-modal .filter-text {
  font-weight: 500;
  margin-bottom: 15px;
}

.new-shopping-list-modal .food-select {
  margin-bottom: 15px;
}

.shopping-list-container {
  margin-top: 15px;
}

.filter-button {
  font-size: 35px;
  border-radius: 5px;
  color: black;
  cursor: pointer;
}

.recipe-search .ant-input-wrapper .ant-input-group-addon:first-child {
  border-radius: 10px 0px 0px 10px;
}

.favorite-recipes {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

@media screen and (max-width: 768px) {
  #edamam-badge {
    display: flex;
    justify-content: center;
  }
  .recipe-search-section {
    display:block;
  }
  .recipe-search {
    margin-bottom: 5px;
  }
  .recipe-search-bar .ant-btn.ant-btn-ghost {
    width: 100%;
    height: 40px;
  }
  .filter-button {
    width: 100%;
  }
  .checkbox-filters {
    display: block;
  } 
  .recipe-search-card .main-page-title {
    text-align: center;
    margin-top: 15px;
  }
  .ant-dropdown-menu.filter-menu {
    margin-bottom: 80px;
  }
}