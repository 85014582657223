.menu-footer {
    display: flex;
    width: 100%;
    justify-content: space-around;
    align-items: center;
    font-size: 12px;
    background-color: #4D2BFF;
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 1;
    height: 60px;
}

.menu-footer .menu-item {
    font-weight: 400;
    color: white;
    padding: 10px;
}


@media screen and (max-width: 768px) {
    .icon-wrap{
        margin-top: 6px;
        justify-content: center;
        height: 16px;
    }
  }