.footer .footer-content {
  display: flex;
  flex-direction: column;
}

.footer-links {
  display: grid;
  justify-content: center;
  width: 100%;
  padding-top: 30px;
  padding-bottom: 30px;
}

.footer-bottom {
  padding-bottom: 30px;
}

.footer.ant-layout-footer {
  background: black;
  /* position: absolute; */
  bottom: 0;
  width: 100%;
  height: 3.0 rem;   
  padding: 0;  
}

.hide {
  display: none;
}

.footer .link {
  color: #f0f0f0;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: color 250ms ease-in-out;
}

.footer-legal {
  display: flex;
  align-items: space-around;
  padding-top: 20px;
}

.footer-legal .separator {
  padding: 0 10px 0 10px;
  color: white;
}

.footer-menu .link:hover {
  opacity: 1;
  color: #fff;
}

.footer .social-media {
  text-align: center;
}

.footer .social-media .fb-icon,
.footer .social-media .ig-icon {
  font-size: 40px;
  color: #d0d0d0;
  margin: 0 5px 0 5px;
  cursor: pointer;
}

.footer .social-media .fb-icon:hover,
.footer .social-media .ig-icon:hover {
  color: rgba(232,10,170);
}

.links-footer {
  display: block;
}

.footer-logo {
  justify-self: center;
}