.shopping-list-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
}

.suggestic-shopping-list .ant-tabs-bar {
  margin-bottom: 25px;
}

.suggestic-shopping-list .ant-divider-horizontal {
  margin: 10px 0 5px 0;
}

.suggestic-shopping-list .item-text {
  font-size: 14px;
  font-weight: 500;
  color: black;
  line-height: 18px;
}

.suggestic-shopping-list .item-qty {
  font-size: 14px;
  color: #5c5c5c;
  line-height: 18px;
}

@media screen and (max-width: 768px) {

}

.cross {
  position: absolute;
  right: 0;
  top: 3px;
  line-height: 24px;
  font-size: 32px;
  cursor: pointer;
  margin-right: 5px;
  user-select: none;
  transition: .3s;
}

.shopping-item .ant-select-selection--single.ant-select-selection--single, .custom-food-modal .ant-select-selection--single.ant-select-selection--single {
  height: 100%;
  border-radius: 4px;
  width: 100%;
}

.shopping-item .ant-select-selection--single .ant-select-selection__rendered, .custom-food-modal .ant-select-selection--single .ant-select-selection__rendered {
  margin-top: 0;
  height: 30px;
}

.shopping-item .ant-select-selection-selected-value, .custom-food-modal .ant-select-selection-selected-value {
  line-height: 30px;
}

.mb-0 {
  margin-bottom: 0;
}

