.open-card-content .section {
  margin-bottom: 50px;
}

.open-card-content .section .global-card{
  margin-bottom: 20px;
}

.open-card-content .column-container {
  padding: 30px 0 30px;
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(auto-fit, minmax(330px, 1fr));
}

.open-card-content .column {
  width: 100%;
  max-width: 400px;
}

.open-card-content .text {
  font-size: 16px;
  line-height: 26px;
  color: #000000;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
}

.open-card-content .img {
  width: 100%;
  border-radius: 38px;
}

.open-card-content .img-small {
  height: 150px;
  margin: 0 0 15px;
}

.open-card-content .subtitle {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 20px;
  line-height: 36px;
  color: #000000;
  text-transform: uppercase;
  font-style: italic;
}

.open-card-content .section-title{
  font-size: 34px;
  font-weight: 700;
  line-height: 36px;
  color: #000000;
  text-transform: uppercase;
  font-style: italic;
}

.open-card-content .flex-cards {
  display: grid;
  grid-gap: 10px;
}

.open-card-content ul {
  padding-left: 40px;
}

.completed-btn:disabled,
.completed-btn:disabled:hover{
  color: white;
  background-color:#2761f1;
  border: none;
  cursor: auto;
}


@media screen and (max-width: 768px) {
  .open-card-content .column-container {
    display: block;
  }
}