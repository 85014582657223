.content.assessment {
  height: 100vh;
}

.content.short-assessment {
  min-height: 100vh;
  padding-top: 0px;
  /* padding-top: 153px; */
  padding-bottom: 80px;
  font-size: 20px;
  text-align: right;
  line-height: 30px;
  background: white;
  position: relative;
  z-index: 99;
  margin-top: -40px;    
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
}

.macros-input, .macros-input input {
  width: 80px;
  text-align: center;
}

.fixed-progress-bar {
  /* position: fixed; */
  position: static;
  left: 0;
  width: 100%;
  /* padding: 10px 50px;
  top: 64px; */
  top: 0px;
  z-index: 9;
  background-color: white;
  padding: 15px 50px 10px; 
  z-index: 100; 
  border-top-left-radius: 30px;
  border-top-right-radius: 30px; 
}

.ant-modal-body .modal-content .danger-icon {
  margin: 0 auto;
  margin-bottom: 30px;
  font-size: 30px;
  color: #ff9505;
}

.ant-modal-body .modal-content {
  display: flex;
  flex-direction: column;
}

.short-assessment .assessment-questions {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  max-width: 660px;  
  text-align: center;    
  position: relative;       
}

.short-assessment .assessment-questions .btn-wrap {
  position: fixed;
  left: 0; right: 0; bottom: 0;
  margin-left: auto;
  margin-right: auto;  
  padding: 15px;
  /* background-color: #FFF; */
}
.short-assessment .assessment-questions .btn-wrap .next-prev-btn {
  max-width: 960px;
  margin: 0 auto;
}

.calculation-card-content {
  display: flex;
  flex-direction: column;
  padding: 40px 24px 40px 24px;
}

.results-cards .card .text{
  font-family: 'Montserrat', sans-serif!important;
}

.short-assessment .back {
  cursor: pointer;
  font-weight: 600;
  color: black;
}

.que-num {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #4F4F4F;
}
.que-num b {
  color: #333333; 
  font-size: 18px; 
  vertical-align: middle;
}

.assessment-questions .subtitle-container {
  display: grid;
  grid-template-columns: 10% 90%;
  width: 100%;
  align-items: center;
  background-color: #f1ed83;
  border-radius: 10px;
  margin-bottom: 10px;
  padding: 10px 15px;
}

.assessment-questions .subtitle-container .icon {
  font-size: 20px;
  color: black;
}

.assessment-questions .subtitle-container .text {
  font-size: 13px;
  line-height: 15px;
  text-align: left;
  color: black;
}

.assessment-questions h1 {
  margin-bottom: 20px!important;
}

.assessment-questions .option {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 5px;
  border-radius: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  background-color: #f6f8fa;
  border: 1px solid #f6f8fa;
}

.assessment-questions .option:hover {
  background-color: #f1f1f1;
  /* border: 1px solid #f0f0f0; */
  /* box-shadow: 0 0 11px rgba(33,33,33,.2);  */
}

.assessment-questions .option.active {
  border: 1px solid #34f5c5!important;
  background-color: #a3fce1;
  /* border: 2px solid #0065ff!important; */
  /* border: 1px solid #5400fc!important; */
  /* border: 1px solid #f0f0f0; */
  /* box-shadow: 0 0 11px rgba(33,33,33,.2);  */
}

.assessment-questions .option .option-text {
  font-size: 20px;
  font-weight: 500;
  color: black;  
}

.assessment-questions .option .anticon svg {
  font-size: 16px;
  color: #333333;
  margin-left: 10px;
}
.assessment-questions .option.que-goal {
  width: calc(100% - 60px);  
  overflow: visible;
}
.assessment-questions .option.que-goal .custom-tooltip {
  position: absolute;
  top: 50%; right: -40px;
  transform: translateY(-50%);
  
}
.assessment-questions .option.que-goal .custom-tooltip svg {color: #BDBDBD;}

.assessment-questions .ant-input {
  padding: 0 15px;
  border-radius: 10px;
  height: 46px;
  font-size: 16px;
  color: #333333;
}
.assessment-questions .ant-textarea {
  height: inherit;
  font-size: 16px;
  padding: 10px 15px;
}

.assessment-questions .ant-input.no-border-radius {
  border-radius: 10px 0px 0px 10px!important;
}

.assessment-questions .ant-radio-button-wrapper {
  height: 46px;
  display: flex;
  align-items: center;
  border-radius: 0px 10px 10px 0px;
}

.assessment-questions .ant-radio-button-wrapper:first-child {
  border-radius: 0px;
}

.assessment-questions .border-right.ant-radio-button-wrapper {
  border-radius: 0px 10px 10px 0px;
}

.assessment-questions .ant-radio-button-wrapper:focus {
 background: #5400fc;
}


.assessment-results .ant-modal-content .ant-input {
  padding: 15px;
  border-radius: 10px;
  margin-bottom: 10px;
  height: 46px;
  font-size: 15px;
  color: black;
}

.ant-modal-content .ant-modal-title {
  font-size: 16px;
  font-weight: 600;
}

.assessment-questions .assessment-button,
.assessment-questions .assessment-button:hover,
.assessment-questions .assessment-button:focus {
  padding: 0 15px;
  border-radius: 50px;
  max-width: 130px;
  height: 50px;
  width: 100%;
  color: white;
  font-size: 18px;
  font-weight: 600;
  /* background:#5400fc; */
  background:#2761f1;
  cursor: pointer;
}

.assessment-questions .assessment-button:disabled {
  cursor: not-allowed;
}
.assessment-back-btn {
  font-size: 18px;
  font-weight: 600;
  color: #333333;
  background-color: #f5f5f5;
  padding: 0 15px;
  max-width: 130px;
  height: 50px;
  width: 100%;  
  text-align: center;
  border-radius: 50px;
  justify-content: center;
  border: none !important;
}
.assessment-back-btn:hover {
  color: #4c2cff;
}

.assessment-questions .error-message {
  color: red;
  font-size: 13px;
  margin-top: 10px;
}

.assessment-results {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  max-width: 800px;
}

.blog-card-category {
  font-weight: 400;
  font-size: 14px!important;
  color: #FF0066!important;
  letter-spacing: .75px;
}

.blog-card-category.macro-label {
  margin-bottom: 0px!important;
  /* color: #5400fc!important; */
  color: #777799!important;
}

.blog-card-category.stats {
  margin-bottom: 0px!important;
  color: #FF0066!important;
}

.blog-card-category.stats.standard-color {
  color: black!important;
}

.assessment-results .results-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 50px;
}

.assessment-results .title {
  text-align: left;
  font-size: 24px;
  font-weight: 500;
}

.assessment-results .send-mail {
  margin-bottom: 40px;
  font-weight: 600;
  cursor: pointer;
  color: black;
  justify-content: center;
}

.assessment-results .send-mail .send-link {
  font-size: 13px;
  margin-left: 5px;
}

.assessment-questions .assessment-question-title {
  font-size: 19px;
  padding-bottom: 20px;
  font-weight: 600;
  color: black;
}

.results-info .results-cards {
  width: 100%;
}

.assessment-results .subtitle {
  line-height: 20px;
  font-weight: normal;
  font-size: 16px;
  text-align: left;
}

.result-page-programs .program .title {
  color: white;
  font-weight: 600;
  font-size: 25px;
  margin-bottom: 0px;
}

.result-page-programs .program .subtitle {
  color: white;
  margin-bottom: 15px;
}

.next-steps .title {
  margin-bottom: 10px;
}

.results-cards .card {
  padding: 10px 30px 10px;
  border-radius: 10px;
  background: #fafafa;
  text-align: left;
  margin-bottom: 15px;
}

.next-step .result-page-programs {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-gap: 10px;
}

.result-page-programs .program {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 40px;
  border-radius: 10px;
  margin-top: 20px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  cursor: pointer!important;
  position:relative;
  /* transform: scale(1.2); */
} 

.result-page-programs .program:hover {
  color: black;
} 

.input-error {
  color: red;
  font-size: 12px;
  margin: 10px 0 0 0;
}

.input-success {
  color: #5cb85c;
  font-size: 12px;
  margin: 10px 0 0 0;
}

.result-page-programs .program:before {
  content:"";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 10px;
  background:
      linear-gradient(white,white) top left,
      linear-gradient(white,white) top left,
      linear-gradient(white,white) bottom left,
      linear-gradient(white,white) bottom left,
      linear-gradient(white,white) bottom right,
      linear-gradient(white,white) bottom right,
      linear-gradient(white,white) top right,
      linear-gradient(white,white) top right;
  background-size: 20px 2px,2px 20px;
  background-origin: content-box;
  background-repeat: no-repeat;
  transition: 0.3s all;
}

.result-page-programs .program:hover:before {
  padding:5px;
}

.form-name {
  text-align: left; 
  font-weight: 500;
  font-size: 14px;
  color: black;
}

.assessment-results .social-media {
  display: flex;
  align-items: center;
  justify-content: center;
}

.assessment-results .social-media a,
.assessment-results .social-media a {
  color: #d0d0d0;
  font-size: 40px;
  margin: 15px 8px 0px 8px;
}

.assessment-results .social-media .fb-icon:hover,
.assessment-results .social-media .ig-icon:hover {
  color: rgba(232,10,170);
}

.ant-popover {
  width: auto;
  max-width: 300px!important;
  font-size: 12px;
  color: white;
  line-height: 16px;
}
.ant-popover-inner-content {
  padding: 3px 16px;
  font-weight: 500;
  color: black;
}

.ant-popover-title {
  min-width: auto;
  border-bottom: none;
  padding-bottom: 0;
  min-height: 0;
}

.ant-popover-inner {
  border-radius: 10px;
}

.blog-card-subtitle {
  border-radius: 0 0 10px 10px;
  font-size: 15px;
  border-top: 0;
  color: #405261;
  line-height: 1.5em;
  font-family: 'Montserrat', sans-serif;
}

.assessment-badge {
  padding: 0px 5px;
}

.ant-calendar-disabled-cell.ant-calendar-today .ant-calendar-date::before {border: none !important;background-color: transparent;}

.flex-start {align-items: flex-start !important;}
.text-left {text-align: left !important;}
.text-right {text-align: right !important;}
.text-center {text-align: center !important;}

.mt-auto {margin-top: auto;}
.ml-auto {margin-left: auto;}

.assessment-questions .option {
  position: relative;
  /* background-color: #FFF;
  border: 1px solid #BDBDBD; */
  border: 1px solid transparent !important;
  background-color: transparent !important;
  border-radius: 10px;
  overflow: hidden;
  z-index: 0;  
  margin-bottom: 10px;
  min-height: 66px;
}
.assessment-questions .option.active {
  border: 1px solid transparent !important;
  background-color: transparent !important;
}
.assessment-questions .option::after {
  position: absolute;
  content: "";
  left: 2px; right: 2px;
  top: 2px; bottom: 2px;
  width: calc(100% - 4px); 
  height: calc(100% - 4px);
  border-radius: 8px;
  z-index: -1;  
  background-color: #FFF;  
}
.assessment-questions .option::before {
  position: absolute;
  content: "";
  left: 0px; right: 0px;
  top: 0px; bottom: 0px;
  width: 100%; height: 100%;
  z-index: -2;
  border-radius: 10px;
  border: 1px solid #e0e0e0;
}
.assessment-questions .option.active::before {
  border: none;
  background: linear-gradient(to right, #2761f1 0%, #FF0066 100%);
  background-image: -moz-linear-gradient(to right, #2761f1 0%, #FF0066 100%);
  background-image: -webkit-linear-gradient(to right, #2761f1 0%, #FF0066 100%);
  background-image: linear-gradient(to right, #2761f1 0%, #FF0066 100%);
}
.assessment-questions .option.card-piechart .ant-input {
  padding: 0 10px;
  height: 40px;
}

/* .custom-radio {
  padding: 15px;  
} */
.custom-chart {
  display: block;
  width: 100%;
  padding: 15px;
  border-top: 1px solid #BDBDBD;
}

.custom-ant-slider .ant-slider-dot,
.custom-ant-slider .ant-slider-rail,
.custom-ant-slider .ant-slider-track {display: none;}

.custom-ant-slider .ant-slider {
  padding: 15px 0; 
  margin-bottom: 50px; 
  position: relative;
  background: rgb(204,204,204);
  background: -moz-linear-gradient(90deg, rgba(204,204,204,1) 0%, #2761f1 22%, #FF0066 100%);
  background: -webkit-linear-gradient(90deg, rgba(204,204,204,1) 0%, #2761f1 22%, #FF0066 100%);
  background: linear-gradient(90deg, rgba(204,204,204,1) 0%, #2761f1 22%, #FF0066 100%);  
}


.custom-ant-slider .ant-slider-mark {top: 50px;}
.custom-ant-slider .ant-slider-handle {
  width: 25px; height: 25px;
  background-color: #BDBDBD !important;
  border: 5px solid #FFF !important;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.25);
}
.custom-ant-slider .ant-slider-step {
  top: 0;
  height: 50px;
  overflow: hidden;
}
.custom-ant-slider .ant-slider-step::before {
  position: absolute;
  content: "";
  left: 0; right: 0;
  top: -28px; bottom: 0;  
  border-top: 40px solid #FFF;    
  transform: rotate(-2.5deg);
}
.custom-ant-slider .ant-slider-step::after {
  content: '|||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||';
  display: flex;
  color: #ffffff;
  font-size: 65px;
  position: absolute;
  left: 0px; bottom: 0;
  letter-spacing: -10px;
  font-weight: inherit;
  font-family: inherit !important;
}

.custom-ant-slider p {font-size: 16px; line-height: 24px; min-height: 60px;}

/************************
      Responsive
*************************/
.ant-checkbox-wrapper,
.ant-checkbox-wrapper + .ant-checkbox-wrapper {margin-left: 0; margin-right: 15px; margin-bottom: 15px;}

.custom-checkbox {
  padding: 4px 15px;
  min-height: 40px;
  background: #FFF;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 50px;  
  font-weight: 400;
  font-size: 16px;  
  line-height: 30px;
  color: #333333;
  position: relative;
  z-index: 0;  
}

.custom-checkbox .ant-checkbox-inner {
  top: 2px; left: -5px;
  width: 25px; height: 25px;
  border-radius: 50px;
  
}
.custom-checkbox .ant-checkbox-inner::after {
  top: 48%; left: 30%;  
  width: 8px; height: 12px;
}
.ant-checkbox + span {padding-right: 5px;padding-left: 5px;}
.ant-checkbox:hover::after, .ant-checkbox-wrapper:hover .ant-checkbox::after {visibility: hidden;}
.ant-checkbox-checked::after,
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #FF0066;
  border: 1px solid #FF0066;
}

.custom-checkbox::after {
  position: absolute;
  content: "";
  left: 2px; right: 2px;
  top: 2px; bottom: 2px;
  width: calc(100% - 4px); 
  height: calc(100% - 4px);
  border-radius: 50px;
  z-index: -1;  
  background-color: #FFF;  
}
.custom-checkbox::before {
  position: absolute;
  content: "";
  left: 0px; right: 0px;
  top: 0px; bottom: 0px;
  width: 100%; height: 100%;
  z-index: -2;
  border-radius: 50px;
  border: 1px solid #f0f0f0;
}
.custom-checkbox.ant-checkbox-wrapper-checked::before {
  border: none;
  background: linear-gradient(90deg, #2761f1 0%, #FF0066 100%);
  background-image: -moz-linear-gradient(90deg, #2761f1 0%, #FF0066 100%);
  background-image: -webkit-linear-gradient(90deg, #2761f1 0%, #FF0066 100%);
  background-image: linear-gradient(90deg, #2761f1 0%, #FF0066 100%);
}

@media screen and (max-width: 768px) {
  .blog-card-subtitle {font-size: 14px;}
  .content.short-assessment {border-top-left-radius: 30px;border-top-right-radius: 30px; }
  /* .fixed-progress-bar {padding: 30px 30px 10px; z-index: 10000; border-top-left-radius: 30px;border-top-right-radius: 30px; top: 80px;} */

  .assessment-results .results-info {display: flex; flex-wrap: wrap; justify-content: space-around; margin-bottom: 0px;}
  .assessment-results .results-info .ant-progress {margin-bottom: 30px;}
  .assessment-results .title {text-align: center;}

  .assessment-questions .assessment-question-title {font-size: 14px; line-height: 20px;}
  .assessment-questions h1 {font-size: 22px;}
  .assessment-questions .option {padding: 10px 15px;}
  .assessment-questions .option .option-text {font-size: 16px;}
  .assessment-questions .ant-input {padding: 0 15px; height: 44px; font-size: 16px;}
  .assessment-questions .ant-textarea {padding: 10px 15px;}
  .assessment-questions .ant-radio-button-wrapper{height: 44px;}
  
  .short-assessment .assessment-questions .btn-wrap {background-color: #FFF;}
}