/* Styles for the parent component .program-recommendation-page */
.program-recommendation-page {
  background-color: white;
  height: calc(100vh - 90px);
  display: grid;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative; 
  overflow: hidden;
}

.input-section .option-text {
  font-size: 16px;
  color: black;
  line-height: 24px;
}

.program-recommendation-page .input-container {
  opacity: 0.9;
  bottom: 30px;
  padding: 24px;
  background-color: #eff0f0;
  position: relative;
  z-index: 1;
  border-radius: 20px;
  width: 100%;
  min-width: 600px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

/* Your existing styles for .input-label */
.input-label {
  font-size: 16px;
  font-weight: 700;
  font-style: italic;
  text-transform: uppercase;
  font-family: 'Montserrat', sans-serif;
  margin-right: 10px;
  width: 100%;
  color: black;
  margin-bottom: 5px;
}

/* Styles for the emoji background */
.emoji-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100vh - 90px);
  display: grid; /* Use grid layout for even distribution */
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr)); /* Adjust column width as needed */
  grid-gap: 20px; /* Adjust the spacing between icons */
  align-items: center;
  justify-content: center;
  z-index: 0;
  animation: floatEmoji 10s infinite;
}

.emoji-item {
  font-size: 48px;
  opacity: 1;
  margin: 0;
  margin: 0 auto;
  transition: transform 0.3s ease-in-out
}

.emoji-item:hover {
  /* animation: floatEmoji 10s infinite; */
}

/* Apply the floating animation only to the 'animate' class on hover */
.emoji-background .emoji-item.animate:hover {
  animation: floatEmoji 10s infinite;
}

.program-recommendation-page .input-container .ant-select-selection-item {
  display: flex;
  align-items: center;
}

.call-to-action-btn {
  transition: transform 0.2s ease;
  display: flex;
  justify-content: center;
  align-items: center;
}

.call-to-action-btn.secondary {
  font-size: 14px!important;
}

.call-to-action-btn:hover {
  box-shadow: 0 10px 20px -8px rgba(0, 0, 0,.7);
}

.diagonal-split-background {
  position: relative;
  min-height: 100vh; /* Adjust the height as needed */
  background: linear-gradient(
      45deg, 
      #2761f1 50%, 
      #FF0066 50%
  );
}

@media (max-width: 768px) {
  .program-recommendation-page .input-container {
    bottom: 50px;
    min-width: 300px;
  }
  .input-section .option-text {
    font-size: 18px;
    color: black;
    line-height: 24px;
  }
  .custom-option {
    font-size: 18px;
  }
  .ant-select-arrow {
    font-size: 14px;
  }
}

.program-recommendation-page .ant-select-selection--single .ant-select-selection__rendered {
  margin-top: 0!important;
  /* border: none; */
  display: flex;
  align-items: center;
  background: none;
}

.program-recommendation-page .ant-select-selection--single.ant-select-selection--single,
.program-recommendation-page .ant-select-open .ant-select-selector {
  border: none;
  display: flex;
  align-items: center;
  background: none;
}
/* Keyframes for emoji floating (same as previous) */
@keyframes floatEmoji {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  75% {
    transform: translateY(10px);
  }
}