.ant-modal.recipe-modal .image {
  border-radius: 10px;
  object-fit: contain;
  margin-right: 20px;
  margin-bottom: auto;
}

.recipe-info.bottom-section {
  width: 100%;
  max-width: 500px;
}

.recipe-info.bottom-section .text {
  font-size: 16px;
  font-weight: 600;
  color: #000000;
}

.recipe-info .ingredient {
  display: grid;
  grid-template-columns: repeat(2, 6% 94%);
  padding: 5px 0 5px 0;
}

.recipe-info .ingredient .ingredient-text{
  color: black;
  /* line-height: 24px; */
}

.recipe-info .recipe-content {
 width: 100%;
 margin-bottom: 10px;
}

.recipe-info .ingredient .icon{
  font-size: 24px;
  justify-self: flex-start;
  color: #5400fc;
  line-height: 18px;
}

.recipe-info .btn-group {
  margin-top: 15px;
}

.btn-group .ant-btn.recipe-button {
  margin-right: 5px;
}

.link-text.gradient-text {
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .btn-group .ant-btn.recipe-button {
    width: 100%;
    margin-bottom: 8px;
  }
  .ant-modal.recipe-modal .image {
    margin-right: 0px;
    margin-bottom: 15px;
  }
}
