.exercise-card-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 10px;
  margin-bottom: 5px;
  /* align-items: center; */
  align-items: stretch;
}

/* .empty-card .exercise-card-container {
  display: flex;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 10px;
  grid-auto-rows: 1fr;
  margin-bottom: 20px;
} */