.not-found-height {
  min-height: 65vh;
  display: flex;
}

.not-found-center {
  margin: auto;
  text-align: center;
  font-size: 24px;
  font-weight: 500;
}

.not-found-center h4 {
  font-size: 40px;
  font-weight:600;
}