.shopping-list-item {
  display: grid;
  grid-template-columns: 5% 95%;
  align-items: center;
  margin-bottom: 25px;
}

.shopping-list-item .ant-tabs-bar {
  margin-bottom: 25px;
}

.shopping-list-item .ant-divider-horizontal {
  margin: 5px 0 15px 0;
}

.shopping-list-item .item-text {
  font-size: 14px;
  font-weight: 500;
  color: black;
  line-height: 18px;
}

.shopping-list-item .item-qty {
  font-size: 14px;
  color: #5c5c5c;
  line-height: 18px;
}
.link-wrap{
  margin-top: 15px;
  text-decoration:underline;
  color: #0645AD;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}
.alternative-list{
  list-style: disc;
  margin-left: 15px;
  font-family: 'Montserrat', sans-serif;
  font-size: 12px;
  color: #000000;
}
.alternative-list li{
 margin-left: 15px;
}
@media screen and (max-width: 768px) {
  .shopping-list-item {
    grid-template-columns: 15% 85%;
  }
}