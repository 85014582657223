.profile-menu {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 1050px;
  height: 40px;
  /* margin: 40px 0 0 0; */
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
}

.profile-menu.no-margin {
  margin-top: 0px;
}

.profile-menu .menu-link {
  display: flex;
  align-items: center;
  margin-right: 20px;
  cursor: pointer;
  padding: 7px 15px;
  color: black;
  border-radius: 10px;
}

.profile-menu .menu-link:hover {
  background: white;
  transition: background-color 250ms ease 0s;
  background-color: rgb(228, 231, 235);
}

.profile-menu .menu-link.active {
  color: black;
  background-color: rgb(228, 231, 235);
}

.profile-menu .menu-link.active p{
  font-weight: 600;
}

.profile-menu .menu-link p {
  font-size: 15px;
  font-weight: 500;
  color: #000000;
}

.profile-menu .menu-link svg {
  font-size: 18px;
  margin-right: 5px;
  color:#5400fc;
}

@media screen and (max-width: 768px) {
  .profile-menu {
    /* flex-direction: column; */
    height: auto;
    overflow-x: overlay;
    height: auto;
  }
  .profile-menu::-webkit-scrollbar {
    display: none;
  }
  .profile-menu .menu-link {
    display: flex;
    justify-content: center;
    width: 100%;
    min-width: 200px;
    margin-right: 5px;
    border: 1px solid #d3d3d3;
    margin-bottom: 5px;
  }
}