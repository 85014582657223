.day-cycling-container {
  display: grid;
  grid-gap: 5px;
}

.carb-tabs {
  display: grid;
  grid-gap: 5px;
  padding: 20px 0 30px;
}

.carb-tabs .option {
  padding: 12px;
  border: 1px solid #f0f0f0;
  border-radius: 10px;
  margin-right: 10px;
  cursor: pointer;
}

.carb-tabs .option.active {
  padding: 12px;
  background-color: #FF0066;
  border-radius: 10px;
  margin-right: 10px;
  cursor: pointer;
  color: white!important;
}

@media screen and (max-width: 768px) {
  .carb-tabs {
    display: grid;
    grid-gap: 10px;
  }
}
