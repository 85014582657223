.achievement-modal {
  display: grid;
  align-items: center;
  justify-content: center;
}

.achievement-modal .modal-content {
  justify-content: center;
}

.achievement-modal .modal-content .title {
  font-size: 18px;
  text-transform: uppercase;
  font-style: italic;
  font-weight: 800;
  text-align: center;
  color: black;
  margin-bottom: 10px;
}

.achievement-modal .modal-content .subtitle {
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  margin-bottom: 15px;
}

.achievement-modal .img {
  height: 80px;
}