.splash-page {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.splash-page .img {
  height: 300px;
  padding: 20px;
}

.splash-page .splash-page-content {
  width: 100%;
  max-width: 500px;
  padding: 20px;
}

.splash-page .splash-page-content .title-section {
 margin-bottom: 20px;
}

.splash-page .splash-page-content .title-section .title {
  margin-bottom: 10px;
}

.splash-page .list {
  margin-bottom: 20px;
}

.splash-page .list .list-item {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.splash-page .benefits {
  display: flex;
  flex-direction: column;
  /* flex-wrap: wrap; */
  margin-bottom: 20px;
}

.splash-page .benefits .benefits-card {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 10% 90%;
  margin-bottom: 15px;
}

.splash-page .benefits .benefits-card p {
  font-size: 14px;
  color: black;
  font-weight: 500;
  line-height: 20px;
}

.pro-label {
  color: white;
  background: #2bffcc;
  padding: 2px 8px;
  border-radius: 10px;
  font-weight: bold;
  margin-left: 10px;
  font-size: 12px;
}

.fixed-label {
  color: white;
  background: #FC1664;
  padding: 2px 8px;
  border-radius: 10px;
  font-weight: bold;
  margin-left: 10px;
  font-size: 12px;
}

.advanced-splash {
  width: 100%;
  max-width: 1050px;
  margin-left: auto;
  margin-right: auto;
}

.advanced-splash .img-block {
  height: 400px;
}

.advanced-splash .splash-header {
  margin-bottom: 80px;
  background: white;
  padding: 24px 50px;
  border-radius: 10px;
}

.advanced-splash .text-block {
  max-width: 500px;
}

.advanced-splash .title {
  font-weight: 600;
  font-size: 35px;
  width: 100%;
  line-height: 45px;
  color: black;
}

.advanced-splash .main-page-subtitle {
  line-height: 30px;
  margin-bottom: 20px
}

.advanced-splash .title-small {
  font-weight: 500;
  font-size: 25px;
  color: black;
  margin: 0 0 30px 0;
  text-align: center;
}

.advanced-splash .features {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  margin-bottom: 80px;
}

.advanced-splash .text {
  font-size: 16px;
  line-height: 24px;
  color: #5c5c5c;
}

.advanced-splash .feature {
  min-height: 230px;
  padding: 24px;
  background: white;
  border-radius: 10px;
  transition: all 0.3s ease-out;
}

.advanced-splash .feature:hover{
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

.advanced-splash .feature-title,
.landing .feature-title {
  font-weight: 600;
  font-size: 24px;
  color: black;
  margin: 12px 0 12px 0;
}

.advanced-splash .feature .icon {
 font-size: 35px;
 margin-right: 20px;
}

.advanced-splash .how-container {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  margin-bottom: 80px;
}

.advanced-splash .how-to,
.landing .testimonial-card {
  min-height: 230px;
  padding: 24px;
  background: white;
  border-radius: 10px;
  transition: all 0.3s ease-out;
}

.advanced-splash .how-to:hover{
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}


.advanced-splash .how-to .text-block{
  margin-bottom: 20px;
}

.advanced-splash .how-to .number{
  font-size: 50px;
  font-weight: 700;
  color: #2bffcc;
  display: grid;

}

@media screen and (max-width: 768px) {
  .splash-page {
    display: flex;
    flex-direction: column;
  }
  .advanced-splash .feature {
    min-height: auto;
  }
  .advanced-splash .how-to{
    display: block;
  }
  .splash-page .splash-page-content {
    width: 100%;
    max-width: 500px;
  }
  .advanced-splash .how-to,
  .landing .testimonial-card {
    border: 1px solid #f0f0f0;
  }
  .advanced-splash .splash-header {
    display: block;
  }
  .advanced-splash .img-block {
    display: none;
  }
}