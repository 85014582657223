.login-form .assessment-title {
  text-align: center;
  font-weight: 400;
  margin-bottom: 20px;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
}

.login-form .policy-notice {
  font-size: 10px;
  color: #3e4346;
  text-align: center;
  line-height: 14px;
}
.login-form .verify-email-notice {
  font-size: 18px;
  color: #FF0066;
  margin-top: 15px;
  text-align: center;
  line-height: 14px;
}

.login-form .modal-label {
  font-size: 0.9rem;
  color: #000000;
}

.login-form .ant-divider-inner-text {
  padding: 0 10px;
  color: #d3d3d3;
  font-size: 12px;
}

.login-form .ant-btn.google-btn {
  margin: 0 auto;
  width: 100%;
  cursor: pointer;
  color: #000000;
  border-radius: 10px;
}

.login-form .ant-btn.google-btn {
  height: 46px;
  margin-bottom: 10px;
}

.login-form .auth-modal-title {
  font-size: 22px;
  margin: 0 auto;
  margin-bottom: 25px;
  font-weight: 500;
  color: #000000;
}

.login-form .ant-btn.google-btn:hover,
.login-form .ant-btn.google-btn:active,
.login-form .ant-btn.google-btn:focus {
  border: 1px solid #f0f0f0;
  color: #000000;
}

.login-form .ant-divider.ant-divider-horizontal.ant-divider-with-text-center {
  margin: 0px 0px;
}

.login-form .ant-divider::before,
.login-form .ant-divider::after {
  top: 0;
}

.login-form .no-account-sct {
  padding: 10px 0 15px 0;
}

.modal-macro-results {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 22px;
}

.login-page {
  display: flex;
  /* height: 100vh; */
  /* height: calc(100vh - 200px);   */
  height: calc(100vh - 50px);
  justify-content: center;
  align-items: center;
  z-index: 11;
  position: relative;
  /* background: white; */
  /*background: linear-gradient(90deg, #FF0066 50%, white 50%);*/
}

.login-container {
  display: grid;
  width: 100%;
  max-width: 400px;
  align-items: center;;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  background: white;
  border-radius: 10px;
  margin: 0px 30px 0px 30px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.login-container .info-section {
  display: flex;
  flex-direction: column;
  border-radius: 10px 0px 0px 10px;
  background: #34f5c5;
  height: 100%;
  padding: 30px;
}

.login-container .info-section.signup {
  display: flex;
  background: white;
}

.login-container .info-section.signup .benefits {
  margin-bottom: 30px;
  display: flex;
  align-items: center;
}
.login-container .info-section.signup .benefits svg {
  width: 60px;
  margin-right: 15px;
}

.login-container .info-section.signup .title {
  font-size: 22px;
  margin: 0 auto;
  margin-bottom: 25px;
  font-weight: 500;
  color: #000000;
}

.login-container .info-section.signup .benefits .benefit-text {
  font-size: 16px;
  line-height: 20px;
  color: black;
}

.login-container .info-section .title {
  font-size: 22px;
  font-weight: 500;
  color: black;
  text-align: center;
}

.login-container .info-section img {
  height: 400px;
}

.login-container .form-section {
  align-items: center;
  justify-content: center;
  padding: 30px;
}

@media screen and (max-width: 768px) {
  .login-page {
    /* background: white;
    height: 100vh */
    height: calc(100vh - 242px);
  }
  .login-container .form-section {
    padding: 0px;
  }
  .login-container .info-section {
    border-radius: 10px;
    display: none;
  }
  .login-container {
    background: none;
    box-shadow: none;
    align-items: center;
    background: white;
    padding: 15px;
  }
  .login-container .info-section.signup {
    display: flex;
    flex-direction: column;
    height: auto;
  }
  .login-container .info-section.signup .benefits {
    text-align: center;
  }
  .login-container .info-section .title {
    display: none;
  }
}
