.food-log-page {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 3;
  grid-template-columns: repeat(auto-fit, minmax(370px, 1fr));
  margin-bottom: 20px;
}

.food-log-page .food-log-card {
  padding: 24px;
  background: white;
  border-radius: 10px;
}

.food-log-card .food-log-row {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(4, 1fr);
  width: 100%;
  height: 40px;
}

.food-log-card .blog-card-category {
  min-width: 75px;
  margin-bottom: 5px;
}

.log-card-content .ant-divider-horizontal {
  margin: 15px 0;
}

.compliance-progress-circle .ant-progress-inner{
  width: 55px!important;
  height: 55px!important;
  font-size: 14px!important;
  font-weight: 500;
}

.food-log-splash-img {
  height: 200px;
  margin-bottom: 20px;
}

@media screen and (max-width: 768px) {
  .food-log-page {
    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  }
  .food-log-splash-img {
    height: 120px;
  }
}
