.landing-page .header {
  height: 600px;
  display: flex;
  align-items: center;
}

.landing-page .ant-collapse {
  font-family: 'Montserrat', sans-serif;
}

.gray {
  background: #FAFAFA;
  /* background: linear-gradient(135deg, #3498db, #2ecc71); */
  /* background: linear-gradient(135deg, #ffc0cb, #f5e1e1); */
}

.landing-page .header-content {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.landing-page .header-content .text {
  width: 100%;
  max-width: 600px;
  margin-right: 40px;
}

.landing-page .title {
  font-size: 34px;
  line-height: 42px;
  font-weight: 800;
  color: black;
}

.landing-page.title.center-aligned {
  text-align: center;
}

.landing-page .subtitle {
  font-size: 16px;
  line-height: 28px;
  font-weight: 400;
  color: black;
}

.landing-page .header-content .text .ant-btn-primary {
 margin-top: 20px;
}

.landing-page .header-content .img img {
 height: 550px;
}

.landing-page .section {
  display: grid;
  width: 100%;
  padding: 80px 30px;
  align-items: center;
  justify-content: center;
}

.landing-page .section.white {
 background: white;
}

.landing-page .section .text-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 800px;
}

.landing-page .section .benefits {
  width: 100%;
  grid-column-gap: 1em;
  grid-row-gap: 1em;
  flex-wrap: wrap;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.landing-page .section .benefits-info {
  grid-column-gap: 0.5em;
  grid-row-gap: 0.5em;
  text-align: left;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
}

/* Container for the "What You Get" items */
.what-you-get {
  display: grid;
  gap: 20px;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.what-you-get-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.what-you-get-item .text {
  width: 100%;
  max-width: 450px;
}

.what-you-get-item img {
  max-height: 400px;
}

.container {
  display: flex;
  justify-content: center;
  grid-gap: 80px;
  flex-wrap: wrap;
  width: 100%;
  max-width: 1200px;
}

.how-it-works-card {
  max-width: 500px;
  padding: 24px;
  border-radius: 10px;
}

.how-it-works-card:hover {
  box-shadow: 0 3px 25px 0 rgba(0, 0, 0, 0.05);
  transition: ease-in .4s;
}

.centered-container {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 80px 30px;
  background: white;
}

.max-width-container {
  max-width: 1200px;
  width: 100%;
}

.number-of-workouts-container {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
}

.number-of-workouts-container .paragraph {
  width: 100%;
  max-width: 500px;
}

.number-of-workouts-container .text-section {
  margin-left: 40px;
}

.number-of-workouts-container .link-container {
 display: flex;
 align-items: center;
 color: blue;
 font-size: 15px;
 margin-top: 10px;
 font-family: 'Montserrat', sans-serif;
}

.pricing-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: center;
  padding: 24px;
  border-radius: 10px;
  box-shadow: 0 3px 25px 0 rgba(0, 0, 0, 0.05);
  grid-gap: 30px;
}

.pricing-container img {
  height: 500px;
  margin: 0 auto;
}

.pricing-items {
  display: flex;
  align-items: center;
}

.pricing-items .button {
  padding: 3px 12px;
  background-color: red;
  color: white;
  font-weight: 800;
  border-radius: 50px;
  font-size: 14px;
  margin: 0 20px;
  text-align: center;
}

.pricing-items .actual-price {
  text-decoration: line-through;
  color: #98A1A8!important;
  font-weight: 800;
  font-size: 35px;
}

.pricing-items .discounted-price {
  font-weight: 800;
  font-size: 36px;
}

.full-width-button {
  width: 100%!important;
}

.landing-button:hover {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

@media (max-width: 768px) {
  .landing-page .header {
    height: auto;
    padding: 30px;
  }
  .landing-page .header-content {
    flex-direction: column-reverse;
    align-items: center;
  }
  .landing-page .title {
    font-size: 30px;
    line-height: 38px;
  }
  .landing-page .header-content .text {
    margin-right: 0px;
    text-align: center;
  }
  .landing-page .header-content .img img {
    height: 350px;
    margin-bottom: 0px;
   }
  .number-of-workouts-container {
    display: grid;
    text-align: center;
  }
  .number-of-workouts-container .text-section {
    margin-left: 0px;
  }
  .number-of-workouts-container .link-container {
    justify-content: center;
   }
   .what-you-get-item img {
    max-height: 250px;
  }
  .what-you-get-item .text {
    width: 100%;
    max-width: 350px;
  }
  .pricing-items {
   display: grid;
   justify-content: center;
  }
  .pricing-container {
    grid-template-columns: 1fr;
  }
  .pricing-container img {
    max-height: 300px;
  }
  .list-container .subtitle{
    display: grid;
    align-items: start;
    grid-template-columns: 10% 90%;
  }
  .landing-page .content {
    padding: 0px;
  }
  .landing-page .how-it-works-card .title {
    line-height: 32px;
    margin-top: 15px;
  }
  .landing-page .section {
    padding: 60px 30px;
  }
  .landing-page .section .benefits {
    grid-template-columns: 1fr;
  }
}

.offer-card {
  border-radius: 20px;
  padding: 20px;
  position: relative;
  width: 100%; /* Adapt to your layout */
  max-width: 1000px; /* Or any max-width you prefer */
  margin: 0 auto;
  /* box-shadow: 0 6px 12px rgba(0, 0, 0, 0.25); */
  box-shadow: 0 2px 13px rgba(0,0,0,.08);
  color: black;
  background: #fafafa;
}

.offer-badge {
  position: absolute;
  top: -15px;
  right: -15px;
  background: #FF0066; /* Brand color for labels */
  color: white;
  padding: 5px 10px;
  border-radius: 50px;
  font-weight: bold;
  font-size: 0.8em;
  white-space: nowrap; /* Keeps the text on one line */
  z-index: 10; /* Ensure it's above other elements */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}


.offer-content {
  text-align: center;
}

.offer-title {
  font-size: 1.5em;
  margin-bottom: 10px;
  font-weight: 700;
}

.offer-description {
  font-size: 1em;
  margin-bottom: 20px;
}

.offer-warning {
  font-weight: bold;
  margin-bottom: 20px;
}

/* Responsive design */
@media (max-width: 768px) {
  .offer-badge {
    top: auto; /* Remove the top positioning */
    bottom: 100%; /* Position it above the card */
    right: 10px; /* Maintain a consistent spacing from the right */
    transform: translateY(10px); /* Push it down a bit so it's not sticking to the card edge */
    font-size: 0.7em; /* Adjust the font size for smaller screens if necessary */
  }

  .offer-title {
    font-size: 1.4em; /* Adjust the title size for smaller screens */
    padding-top: 20px; /* Add padding to ensure the title does not go under the badge */
  }
}
