.programs-page {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  grid-gap: 10px;
  grid-auto-rows: 1fr;
  justify-items: center;
}

.programs-page .preferences-section .main-text {
  font-size: 18px;
  font-weight: 500;
  line-height: 18px;
}

.programs-page .preferences-section .preferences-card {
 margin-bottom: 30px;
}

.blog-card-category.macro-label.plan {
  margin-bottom: 0px!important;
}

@media screen and (max-width: 768px) {
  /* .blog-card-category {
    font-size: 12px!important;
  } */
}