.meal-plan-page .meal-plan-macros {
  display: flex;
  padding: 20px 0 0 0;
}

.meal-plan-page .meal-plan-macros .ant-progress {
  padding: 0 10px 0 10px;
}

.home-macro-card .ant-progress .ant-progress-inner {
  width: 110px!important;
  height: 110px!important;
}

.home-macro-card .ant-progress .ant-progress-inner .ant-progress-text {
  font-weight: 500;
  font-size: 25px;
  color: black;
}

.day-container {
  padding-bottom: 15px;
}

.day-container .day-text,
.suggestic-shopping-list .aisle-section {
  font-size: 16px;
  font-weight: 600;
  color: black;
}

.daily-meals-wrapper {
  margin-top: 10px;
}

.previous-week-badge,
.previous-week-badge:focus,
.previous-week-badge:hover,
.ant-btn.previous-week-badge:disabled,
.ant-btn.previous-week-badge:active {
  background: #2761f1;
  padding: 10px 20px;
  border-radius: 20px;
  font-weight: 600;
  cursor: pointer;
  height: 46px;
  border: none;
  color: white;
  margin-bottom: 30px;;
}

.meal-plan-page .ant-progress-text {
  color: black!important;
}

.meal-plan {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1050px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 15px;
}

.meal-plan .add-to-list,
.meal-plan .add-to-list:focus,
.meal-plan .add-to-list:hover,
.meal-plan .add-to-list:disabled,
.meal-plan .add-to-list:active {
  background: none;
  border: none;
  box-shadow: none;
  color: black;
}

.meal-plan .add-to-list:last-child {
  margin-bottom: 20px;
}

.meal-plan .meal-card .meal-type-text {
  font-weight: 800;
  font-size: 12px;
  letter-spacing: .75px;
  text-transform: uppercase;
  line-height: 12px;
  margin-bottom: 5px;
  color:#FF0066;
}

.meal-plan .meal-card {
  /* display: flex;
  justify-content: space-between;
  align-items: center; */
  padding: 15px 20px;
  box-shadow: 0 5px 10px rgb(154 160 185 / 5%), 0 15px 40px rgb(166 173 201 / 20%);
  border-radius: 10px;
  margin-bottom: 10px;
  background: white;
  cursor: pointer;
}

.meal-plan .meal-card .alternative-recipes {
  margin-top: 20px;
}

.meal-card .meal-card-content {
  display: flex;
  grid-gap: 5px;
  width: 100%;
  align-items: center;
}

.meal-plan .meal-modal {
  display: flex;
  flex-direction: column;
}

.ant-modal.recipe-modal .ant-modal-body {
  padding: 0!important;
}

.meal-modal .recipe-img {
  border-radius: 10px 10px 0 0;
  height: 220px;
  background-size: cover;
  background-repeat: no-repeat!important;
  background-position: center center;
}

.meal-modal .recipe-content {
  padding: 24px;
}

.meal-modal .recipe-content .title {
  font-size: 18px;
  font-weight: 500;
  text-transform: uppercase;
  color: #000000;
  line-height: 18px;
}

.meal-modal .recipe-ingredients.margin-bottom {
  margin-bottom: 25px;
}

.meal-modal .recipe-nutrients,
.meal-modal .recipe-ingredients {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.meal-modal .recipe-modal-btn {
  background: #2761f1;
  color: white;
  font-weight: 600;
  border: 1px solid #2761f1;
  width: 100%;
  border-radius: 10px;
}

.recipe-nutrients .ant-badge-status-text {
  font-size: 14px;
  color: #000000;
}

.meal-modal .nutrition-label {
  font-size: 14px;
  line-height: 14px;
  font-weight: 500;
}

.recipe-content .meal-card-text {
  font-size: 14px;
  color: #000000;
}

.recipe-content .meal-card-title {
  font-size: 14px;
  margin-bottom: 10px;
  font-weight: 500;
  color: #FF0066;
}

.recipe-ingredients .ant-divider,
.recipe-instructions .ant-divider {
  margin: 5px 0 5px 0;
}

.recipe-ingredients .ant-divider:last-child,
.recipe-instructions .ant-divider:last-child {
  display: none;
}

.recipe-ingredients .ingredients {
  display: grid;
  grid-template-columns: 3% 97%;
}

.meal-modal .recipe-content .details {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 15px 0 15px;
}

.meal-card .meal-card-img {
  border-radius: 50%; 
  height: 100px;
  width: 100px;
  object-fit: cover;
  margin-right: 20px;
}

.meal-card .home-card-text.alternative-recipe {
  padding: 10px;
  border-radius: 10px;
  background: linear-gradient(to right ,#1dcdfe, #21d0b2);
  margin-bottom: 10px;
  color: white;
  font-weight: 500;
}

.meal-card .home-card-text.alternative-recipe:hover {
  font-weight: 600;
}

.meal-card .ant-divider-horizontal {
  margin: 10px;
}

.meal-plan .shopping-list-buttons {
  margin-bottom: 30px;
}

.ant-progress-text {
  color: black!important;
}

@media screen and (max-width: 768px) {
  .meal-plan-page .meal-plan-macros .ant-progress {
    padding: 2px;
  }
  .home-macro-card .ant-progress .ant-progress-inner {
    width: 80px!important;
    height: 80px!important;
    font-size: 14px!important;
  }
  .meal-card .meal-card-content {
    display: block;
  }
  .meal-card .meal-card-img {
    margin-bottom: 10px;
  }
  .home-macro-card .ant-progress .ant-progress-inner .ant-progress-text {
    font-weight: 500;
    font-size: 18px;
    color: black;
  }
  .meal-plan .shopping-list-buttons {
    display: block;
  }
  .meal-plan .ant-btn {
    width: 100%;
  }
  .shopping-list-buttons .ant-btn:first-child {
    margin-bottom: 10px;
  }
}