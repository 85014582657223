.stat-page {
  margin-bottom: 50px;
}

.stat-page .stat-section,
.home-page .summary-section,
.home-page .home-stats {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 10px;
  grid-auto-rows: 1fr;
}

.stat-page .stat-section .stat-card,
.home-page .summary-section .summary-card,
.home-page .summary-section .summary-card.compare-content {
  border-radius: 10px;
  padding: 24px;
  background: white;
  box-shadow: 0 5px 10px rgba(154,160,185,.05), 0 15px 40px rgba(166,173,201,.2);
}

.home-page .summary-section .main-text {
  font-size: 30px;
  font-weight: 500;
  line-height: 30px;
  color: #000000;
}

.stat-page .stat-section .main-text {
  font-size: 25px;
  font-weight: 500;
  line-height: 30px;
  color: #000000;
}

.stat-page .stat-section .main-text.color,
.stat-page .stat-section .label.color {
  color: #FF0066;
}

.stat-page .stat-card-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 10px;
  grid-auto-rows: 1fr;
  margin-bottom: 20px;
}

.programs-page .preferences-section .main-text {
  font-size: 18px;
  font-weight: 500;
  line-height: 18px;
}

.stat-page .stat-section .summary {
  font-size: 30px;
  font-weight: 500;
  line-height: 30px;
}

.stat-page .stat-section .label{
  align-self: flex-end;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}

.progress-picture-container {
  display: flex;
  justify-items: center;
}

.progress-picture-container .img {
  height: 400px;
  border-radius: 10px;
}

.progress-picture-container .img:not(:last-child) {
  margin-right: 10px;
}

.image-modal-content {
  display: grid;
}

.image-modal-content .select-side {
  display: flex;
  margin: 0 0 15px 0;
}

.image-modal-content .select-side .text.active {
  background: #2761f1;
  font-weight: 600;
  color: white;
  border: 1px solid #2761f1;
}

.image-modal-content .select-side .text {
  font-size: 14px;
  font-weight: 500;
  padding: 0 20px;
  cursor: pointer;
  border-radius: 10px;
  background: #e4e7eb;
  border: 1px solid #e4e7eb;
  margin-right: 15px
}

.compare-content {
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
  color: #000000;
}

.compare-content p {
  font-size: 15px;
  font-weight: 500;
  margin-right: 10px;
}

.compare-content .compare-date-select {
  width: 220px;
  font-size: 16px;
  margin-left: 10px;
}

@media screen and (max-width: 768px) {
  .progress-picture-container {
    display: grid;
  }
  .compare-content {
    flex-direction: column;
  }
  .progress-picture-container .img {
    height: auto;
    width: 100%;
    margin-bottom: 10px;
  }
  .image-modal-content .select-side {
    justify-content: space-around;
  }
  .image-modal-content .select-side .text {
    margin-right: 0;
  }
}