.modal-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.modal-icon {
  font-size: 64px;
  margin-bottom: 16px;
  color: #ff4d4f;
}

.modal-text {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
}