@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,700,800&display=swap');
@import url('https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700,800,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Creepster&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Chiller&display=swap');
@import url('https://fonts.googleapis.com/css?family=Raleway:400,500,600,700&display=swap');



@font-face {
  font-family: 'Brother-bold';
  font-style: normal;
  src: url('./assets/fonts/Brother-1816-Bold.otf') format('opentype');
}

@font-face {
  font-family: 'Brother-1816-Book';
  font-style: normal;
  src: url('./assets/fonts/Brother-1816-Book.otf') format('opentype');
}
* {
  margin: 0;
  padding: 0;
}

.app {
  position: relative;
  min-height: 100vh;
}

.app .content-wrap {
  padding-bottom: 3.0 rem;    /* Footer height */
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
  background-color: #FFFFFF;
  /* background-color: #34394F; */
  font-size: 16px;
}

p {
  /* color: #000000; */
}

h1 {
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
  font-size: 25px;
  font-weight: 600;
  color: #000000;
  line-height: 35px;
}

a,
a:hover{
  color: #0065ff;
}

h2 {
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
  font-size: 20px;
  font-weight: 600;
  color: #000000;
}

h3{
  color: #000000;
}

p {
  margin: 0;
  padding: 0;
}

.normal-width {
  width: 100%;
  max-width: 1150px;
  margin-right: auto;
  margin-left: auto
}

.solid-link,
.solid-link:hover {
  color: #0065ff;
  cursor: pointer;
  font-size: 14px;
}

.white {
  color: white;
}

.black {
  color: black;
}
/* 
#root {
  min-height: 100vh;
} */

.content {
  padding: 120px 50px 50px 50px;
  overflow: auto;
  min-height: calc(100vh - 200px);
}

.content .page-title {
  text-transform: uppercase;
  margin-bottom: 30px;
  letter-spacing: 2px;
}

.content-reg {
  padding: 120px;
}

.subtitle {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 5px;
}

.text-suffix {
  font-size: 15px;
  font-weight: 600;
  margin-left: 3px;
  color: #000000;
}

.form-subtitle,
.modal-subtitle {
  margin: 0;
  font-size: 14px;
  line-height: 18px;
}

.flex {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.baseline {
  align-items: baseline;
}

.block {
  display: block;
}

.space-between {
  justify-content: space-between;
}

.flex-end {
  justify-content: flex-end;
}

.space-around {
  justify-content: space-around;
}

.justify-center {
  justify-content: center;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-center-aligned {
  align-items: center;
}

.flex-end-aligned {
  align-items: flex-end;
}

.flex-start {
  align-items: flex-start;
}

.flex-center {
  justify-content: center;
}

.grid-justify-center{
  display: grid;
  justify-items: center;
}

.full-page {
  height: 100vh;
}

.ant-btn.ant-btn-primary {
  border-radius: 10px;
  border: 1px solid #2761f1;
  background-color:#2761f1;
  color: white;
  font-weight: 600;
  height: 46px;
  font-size: 15px;
}

.ant-btn.ant-btn-primary:hover {
  border: 1px solid rgb(0, 57, 229);
  background-color: rgb(0, 57, 229);
}

.ant-btn.ant-btn-danger {
  border-radius: 10px;
  color: white;
  font-weight: 600;
  font-size: 14px;
  border-color: red;
  height: 46px;
  background: red;
}

.ant-btn.ant-btn-primary.small,
.ant-btn.ant-btn-primary.ant-btn-sm{
  border-radius: 5px;
  border: none;
  background-color:#2761f1;
  border: 1px solid #2761f1;
  color: white;
  font-weight: 500;
  height: 30px;
  font-size: 14px;
  padding: 
}

.ant-btn.ant-btn-sm{
  border-radius: 5px;
  border-color: gray;
  background-color:white;
  color: gray;
  font-weight: 500;
  height: 30px;
  font-size: 14px;
}

.ant-btn.ant-btn-primary:disabled {
  color: rgba(0, 0, 0, 0.25) !important;
  background-color: #f5f5f5 !important;
  border-color: #f5f5f5;
}

.ant-btn.ant-btn-secondary,
.ant-btn.save-btn-modal.ant-btn-secondary,
.ant-btn.save-btn-modal.ant-btn-secondary:hover  {
  background-color: #d0d0d0; 
  border: none;
  border-radius: 10px;
  height: 46px;
  color: white;
  font-weight: 600;
  font-size: 15px;
}

.ant-btn.gray-button {
  background-color: #d0d0d0; 
  border: none;
  border-radius: 10px;
  height: 46px;
  color: white;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 13px;
}

.ant-btn.ant-btn-ghost,
.ant-dropdown-link.ant-dropdown-trigger  {
  border-radius: 10px;
  color: rgb(76, 82, 94);
  font-weight: 600;
  font-size: 14px;
  border-color:rgb(76, 82, 94);
  height: 46px;
} 

.ant-btn.ant-btn-ghost:hover,
.ant-btn.ant-btn-ghost:focus {
  color: rgb(76, 82, 94);
} 

.anticon.anticon-check.ant-steps-finish-icon { 
  color: white;
}

.ant-select-selection:hover,
.ant-select-selection:focus {
  border-color: #f0f0f0!important;
}

.ant-select-disabled .ant-select-selection {
  background: white;
}

.ant-select-disabled {
  color: rgba(0, 0, 0, 0.65);
}

.ant-input {
  border-color: #f0f0f0!important;
}

.ant-input:focus {
  -webkit-box-shadow: none;
    box-shadow: none;
}

.get-email .ant-input {
  padding: 15px;
  border-radius: 10px;
  height: 40px;
  font-size: 15px;
  color: black;
}

.ant-alert {
  margin-top: 10px;
}

.ant-slider-track {
  background: #6242b0;
}

.ant-slider-handle {
  border: 2px solid #6242b0;
}

.ant-slider-handle:hover {
  border: 2px solid #6242b0;
}

.ant-progress-circle.ant-progress-status-success .ant-progress-text{
  color: rgba(0, 0, 0, 0.65)!important;
}

.bg-light {
  background-color: #fafbfe!important;
}

.ant-alert.ant-alert-success .ant-alert-message {
  color: green;
}

.ant-alert.ant-alert-error .ant-alert-message {
  color: red;
}

.ant-alert.ant-alert-error,
.ant-alert.ant-alert-success {
  border: none!important;
  border-radius: 10px;
}

.footer-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ant-carousel .slick-dots-bottom {
  bottom: -30px;
}

.ant-carousel .slick-dots li{
  background: black;
}

/* Form */

.ant-select-lg .ant-select-selection--multiple {
  min-height: 100px;
  border-radius: 10px;
}

.ant-modal-header {
  border-bottom: none;
  padding: 16px 24px 0px 26px;
  border-radius: 10px;
}

.form .ant-select-selection .ant-select-selection,
.modal .ant-select-selection.ant-select-selection--single {
  border-radius: 10px;
  width: 100%;
  border-color: #f0f0f0!important;
}

.form .ant-select .ant-select-selection--multiple {
  border-radius: 10px;
  width: 100%;
  min-height: 46px;
  border-color: #f0f0f0!important;
}

.form .ant-select .ant-select-selection {
  border-radius: 10px;
  width: 100%;
  min-height: 46px;
  border-color: #f0f0f0!important;
}

.form .ant-select .ant-select-selection--multiple .ant-select-selection__choice {
  height: 36px;
  display: flex;
  align-items: center;
}

.form .ant-input,
.modal .ant-input,
.ant-select-auto-complete.ant-select .ant-input,
.ant-calendar-picker-input.ant-input,
.modal .ant-select .ant-select-selection.ant-select-selection--single
{
  border-radius: 10px;
  width: 100%;
  height: 46px;
  border-color: #f0f0f0!important;
  color: black;
}

.ant-calendar-picker {
  width: 100%;
}

.ant-select-selection-selected-value {
  color: #000000!important;
}

.ant-select-dropdown-menu-item {
  color: #000000;
}

/* .ant-select-dropdown-menu-item:hover {
  background: #f0f0f0!important;
} */

.form .select-input,
.modal .select-input{
  width: 100%;
}

.ant-select-selection--single .ant-select-selection__rendered {
  margin-top: 5px!important;
  height: 40px;
}

.ant-select-selection__placeholder {
  line-height: 16px;
}

.ant-select-selection--single.ant-select-selection--single{
  height: 46px;
  border-radius: 10px;
}

.ant-select-selection-selected-value {
  line-height: 37px!important;
}

.ant-calendar-range-picker-separator{
  vertical-align: unset;
} 

.form .label,
.modal .label {
  text-align: left; 
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 3px;
  color: #000000;
  line-height: 22px;
}

.form .form-section {
  align-items: center;
  width: 100%;
  width: 49.5%;
}

.form-section.button-section {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.form .form-section.full-width{
  width: 100%;
}

.form .button {
  height: 46px;
  min-width: 90px;
}

.text-center {
  text-align: center;
}

.large-header-title {
  text-transform: uppercase;
  font-size: 45px;
  text-align: center;
  font-weight: 400;
  color: white;
  margin-bottom: 20px;
}

.ant-menu-horizontal > .ant-menu-item:hover, .ant-menu-horizontal > .ant-menu-submenu:hover, .ant-menu-horizontal > .ant-menu-item-active, .ant-menu-horizontal > .ant-menu-submenu-active, .ant-menu-horizontal > .ant-menu-item-open, .ant-menu-horizontal > .ant-menu-submenu-open, .ant-menu-horizontal > .ant-menu-item-selected, .ant-menu-horizontal > .ant-menu-submenu-selected {
  border-bottom: 2px solid #5400fc;
}

.ant-menu-horizontal > .ant-menu-item > a,
.ant-menu-horizontal > .ant-menu-item > a:hover {
  color: gray;
  font-weight: 600;
}

.ant-menu-horizontal > .ant-menu-item-selected > a,
.ant-menu-horizontal > .ant-menu-item-selected > a:hover {
  color: #5400fc;
  font-weight: 600;
}

.search .ant-input {
  padding: 15px;
  border-radius: 10px;
  height: 46px;
  font-size: 16px;
  color: black; 
  border-color: #f0f0f0!important;
}

.search .ant-input-search-button {
  height: 40px;
}

.title-and-subtitle-section {
  margin-bottom: 15px;
}

.title-and-subtitle-section .section-title {
  color: #000;
  font-size: 16px;
  font-weight: 500;
  margin: 0;
  line-height: 22px;
}

.title-and-subtitle-section .section-subtitle {
  margin: 0;
  font-size: 14px;
  line-height: 22px;
}

.ant-notification.ant-notification-topRight {
  top: 75px!important;
}

.ant-layout-sider-zero-width-trigger,
.ant-layout-sider-zero-width-trigger:hover {
  border-radius: 0 10px 10px 0;
  /* background: #2c2f33; */
  background: #2761f1;
  color: white;
  position: fixed;
  top: 10px;
  z-index: 999;
}

/* .ant-modal-title {
  font-size: 16px;
  border-top: 0;
  color: black;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 3px;
} */

.ant-modal .ant-modal-content {
  border-radius: 10px;
}

.text-gradient {
  background-image: linear-gradient(to right, #2761f1 0%, #FF0066 100%);
  background-text: rgba(39,97,241,1);
  background-size: 100%;
  background-repeat: repeat;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; 
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
}

.gradient-background {
  background: linear-gradient(to right, rgb(29, 205, 254) 0%, rgb(52, 245, 197) 100%);
}

.ant-select-lg .ant-select-selection--multiple .ant-select-selection__rendered li {
  font-size: 14px;
  line-height: 30px;
  font-weight: 500;
  color: white;
}

.ant-select-selection__choice__remove i{
  color: white;
}

.new-shopping-list-modal .ant-select-selection__choice {
  /* background: #4f4bf9; */
  background: linear-gradient(225deg, rgba(39,97,241,1) 18%, rgba(232,10,170,1) 100%);
  border-radius: 10px;

}

.ant-btn.gradient-button,
.ant-btn.gradient-button:hover {
  background: linear-gradient(225deg, rgba(39,97,241,1) 18%, rgba(232,10,170,1) 100%)!important;
  color: white;
}

.dashboard-content.ant-layout-content {
  margin: 0px 50px;
}

.ant-switch-checked{
  /* background-color: #5400fc!important; */
  background: #2bffcc;
}

.ant-switch-checked .ant-switch-inner{
  margin-right: 30px;
}

.ant-layout {
  min-height: auto;
}

.ant-drawer-body {
  padding: 0 20px 20px 20px;
}

.ant-drawer-header {
  padding: 16px 20px;
}

.ant-tabs-nav .ant-tabs-tab {
  padding: 10px 16px;
}

.ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-nav .ant-tabs-tab:hover {
  color:rgb(76, 82, 94);
}

.ant-tabs-ink-bar {
  background-color: rgb(76, 82, 94);
}

.ant-dropdown-menu {
  border-radius: 10px;
}

.ant-dropdown-menu-item,
.menu-item {
  font-weight: 500;
  width: auto;
  padding: 16px 24px;
  border-bottom: 1px solid #f2f4f8;
  font-size: 14px;
  color: #000000;
}

.ant-dropdown-menu-item:last-child {
  border-bottom: none;
}

.ant-dropdown-menu-item:hover {
  background: white;
  color: black;
  border-radius: 10px;
}

.select-option {
  background: white;
}

.ant-calendar-selected-day .ant-calendar-date{
  /* background: #5400fc; */
  background: #34f5c5;
  color: white;
}

.ant-modal-title {
  text-transform: none;
  font-weight: 600!important;
}

.ant-modal-footer{
  border-top: none;
}

.btn-danger,
.btn-danger:hover{
  background: red;
  color: white;
  border-color:red;
  border-radius: 10px;
  font-weight: 600;
  font-size: 14px;
  height: 46px;
}

.ant-tooltip-inner {
  font-size: 13px;
  line-height: 16px;
  font-weight: 500;
  color: #000000A6;
  background: white;
  padding: 6px 10px;
}

.DatePicker_dateLabel__2aluo {
  font-size: 14px!important;
}

.ant-tabs-tab {
  font-size: 14px;
  font-weight: 500;
  color: black;
}

.ant-tabs-tab-active.ant-tabs-tab {
  font-size: 14px;
  font-weight: 600;
  color: black;
}

.ant-tabs-nav .ant-tabs-tab:hover {
  color: black;
}

.ant-input-group-addon {
  border-radius: 0 10px 10px 0;
  background: #2761f1;
  color: white;
  font-weight: 600;
  border: none;
}

.one-rep-max-input .ant-input-group-addon {
  border-radius: 0 10px 10px 0!important;
  background: #2761f1;
  font-weight: 600;
}

.one-rep-max-input .ant-select-selection-selected-value {
  width: 30px;
}

.global-loading {
  font-size: 20px;
}

.ant-collapse-item:last-child > .ant-collapse-content {
  border-radius: 0 0 20px 20px;
}

.icon-img {
  height: 16px;
  margin-right: 10px;
}
.ml-2{
  margin-left: 20px;
}
.cursor-pointer{
  cursor: pointer;
}

.lg-icon {
  font-size: 34px;
  color: #FF0066;
}

.ant-table-thead > tr > th {
  font-weight: 600;
}

.global-card {
  padding: 24px;
  width: 100%;
  background: white;
  box-shadow:  0 5px 10px rgb(154 160 185 / 5%), 0 15px 40px rgb(166 173 201 / 20%);
  border-radius: 10px;
}

.global-card.expanded {
  padding: 24px;
  width: 100%;
  background: white;
  box-shadow:  0 5px 10px rgb(154 160 185 / 5%), 0 15px 40px rgb(166 173 201 / 20%);
  border-radius: 0 0 10px 10px;
}

.global-card .title {
  font-weight: 500;
  font-size: 16px;
  color: #000;
  line-height: 16px;
}

.global-avatar {
  border-radius: 50%;
  height: 70px;
}

.main-page-title-small {
  font-size: 20px;
  line-height: 30px;
  font-weight: 600;
  color: #000000;
}

.anticon.anticon-close.ant-modal-close-icon {
  color: white;
  font-size: 12px;
  background: #FF0066;
  border: 1px solid #FF0066;
  padding: 5px;
  border-radius: 50%;
}

.ant-modal-close-x {
  width: 40px;
  height: 40px;
  line-height: 40px;
}

.ant-modal-footer button + button {
  margin-bottom: 0;
  margin-left: 5px;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color:#4D2BFF;
  border: 1px solid #4D2BFF;
} 

ul li.green::marker {
  color: #2bffcc;
  font-size: 1.5em;
}

ul li.red::marker {
  color: red;
  font-size: 1.5em;
}

.highlight {
  display: inline;
  background: linear-gradient(180deg, rgba(225,225,225,0) 50%, #2761f1 50%);
}

.sticky-btn {
  display: none;
}

.modal .form-section {
  margin-bottom: 5px;
}

.delete-text {
  color: red;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
}

.icon-text-grid {
  display: grid;
  grid-template-columns: 2% 98%;
  grid-gap: 5px;
}

.underlined-title {
  position: relative;
  padding-bottom: 6px; /* Adjust padding to create space for the border */
}

.underlined-title:after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 6px; /* Height of the border */
  background-image: linear-gradient(to right, #2761f1 0%, #FF0066 100%);
}

.small-title {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 20px;
  font-weight: 700;
  color: black;
}

.big-subtitle {
  font-size: 18px;
  line-height: 28px;
  font-weight: 400;
  color: #5C5C5C;
}


path.ant-progress-circle-trail { stroke:#d3d3d3!important }

@media screen and (max-width: 768px) {
  .content-reg {
    padding: 30px;
  }
  .ant-carousel .slick-dots-bottom {
    bottom: -10px;
  }
  .content {
    padding: 80px 30px 30px 30px;
  }
  .large-header-title {
    font-size: 30px;
  }
  .form .form-section {
    width: 100%;
    padding-bottom: 15px;
  }
  .dashboard-content.ant-layout-content {
    margin: 0px 20px;
    padding-bottom: 20px;
  }
  .ant-calendar-range {
    width: 320px;
  }
  .ant-calendar-range-part {
    width: 100%;
  }
  .form .button {
   width: 100%;
  }
  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    padding-left: 16px;
  }
  .ant-calendar-picker-container {
    z-index: 9999;
  }
  .ant-collapse-content > .ant-collapse-content-box {
    padding: 0px;
  }
  .sticky-btn {
    position: sticky;
    width: 100%;
    max-width: 200px;
    margin-left: auto;
    margin-right: auto;
    bottom: 68px;
    margin-top: 20px;
    background: #FFAA58;
    border-radius: 20px;
    height: 46px;
    padding: 0px 10px;
    border: none;
    color: black;
  }
  .icon-text-grid {
    display: grid;
    grid-template-columns: 5% 95%;
  }
}

/*input type number hide increment decrement controls*/
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.form-section .custom-checkbox {
  line-height: 26px;
  min-height: 30px;
  cursor: pointer;
}
.form-section .custom-checkbox .ant-checkbox-inner {
  top: 0px;
  left: -8px;
  width: 22px;
  height: 22px;
}
.form-section .custom-checkbox .ant-checkbox-inner::after {
  width: 6px;
    height: 10px;
}

.rdw-dropdown-selectedtext {
  color: #000 !important;
}
.rdw-option-wrapper {
  padding: 8px !important;
  min-width: 30px !important;
  height: 30px !important;
}
.rdw-fontsize-dropdown {
  min-width: 60px !important;
}
.rdw-link-modal {
  height: inherit !important;
}
.custom-editor {
  border-radius: 10px;
  background-color: #fff;
  padding: 15px 20px;
  margin-top: 5px;
  position: relative;
}
.empty-card {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f0f0f0;
  border-radius: 10px;
  padding: 20px;
  max-height: 400px; /* Adjust the height based on your requirements */
  overflow-y: auto;
}

.action-icon {
  font-size: 28px;
  margin: 5px 0px;
}

.modal-subtitle {
  font-weight: 500;
  font-size: 13px !important;
  color: rgb(90, 100, 116);
  letter-spacing: 0.06em;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  padding: 18px 18px;
}

.ant-collapse-content-box {
  padding: 24px 18px!important;
  background: white;
}

.ant-collapse-borderless > .ant-collapse-item {
  border-bottom: 1px solid #f0f0f0;
}
