.action-footer {
  position: fixed;
  display: flex;
  align-items: center;
  bottom: 0;
  background: transparent;
  height: 60px;
  width: calc(100% - 250px);
  justify-content: center;
}

@media screen and (max-width: 991px) {
  .action-footer {
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .action-footer {
    width: 100%;
    bottom: 60px;
  }
}

@media screen and (max-width: 476px) {
  .action-footer button {
    height: unset;
    padding: 5px;
  }
}