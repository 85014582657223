.content-wrap .logo img {
  margin: 0 auto;
  height: 28px;
}

.site-layout {
  position: relative;
}

.dashboard-content .site-layout-background {
  border-radius: 10px;
  width: 100%;
  max-width: 1050px;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 5px 5px 25px 0px rgba(46, 61, 73, 0.2);
  padding: 50px;
  background: #fff;
  margin-top: 15px;
  margin-bottom: 50px;
}

.site-layout-no-background {
  width: 100%;
  max-width: 1050px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 15px;
  margin-bottom: 50px;
}

.dashboard-content .site-layout-background.user-profile {
  padding: 0px;
  background: #fff;
  border-radius: 10px;
}

.site-layout-background.no-margin-top {
  margin-top: 0px;
}

.dashboard-content .site-layout-background.shopping-list {
  border-radius: 10px;
  width: 100%;
  max-width: 1050px;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 5px 5px 25px 0px rgba(46, 61, 73, 0.2);
  padding: 10px 30px;
  background: #fff;
  margin-top: 30px;
  margin-bottom: 80px;
}

.ant-layout-sider-children {
  display: flex;
  position: fixed;
  width: inherit;
  justify-content: center;
}

.ant-layout-sider-zero-width-trigger {
  position: fixed;
  left: 250px;
  transition: 0.2s;
}

.ant-layout-sider-collapsed .ant-layout-sider-zero-width-trigger {
  left: 0;
}

.ant-layout-sider {
  background: white;
}

.ant-layout-sider-trigger {
  background: white;
}

.ant-layout-sider-trigger svg {
  color: black;
}

.user-dropdown .ant-dropdown-link {
  color: black;
  font-size: 14px;
  font-weight: 500;
}

.user-dropdown .dropdown .img {
  height: 40px;
  cursor: pointer;
  border-radius: 50%;
}

.dashboard-content .edit-profile {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}

.dashboard-content .edit-profile .grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}

.toggle-right {
  position: right;
}

.dashboard-content .edit-profile .form-heading {
  margin-bottom: 15px;
  justify-self: flex-start !important;
}

.dashboard-content .form-title,
.dashboard-content .modal-title {
  color: #000000;
  font-size: 16px;
  font-weight: 600;
  margin: 0;
  line-height: 22px;
}

.dashboard-content .form-subtitle {
  margin: 0;
  font-size: 14px;
  line-height: 18px;
  max-width: 400px;
}

.dashboard-content .edit-profile .form {
  width: 100%;
}

.dashboard-content .form .form-row {
  padding-bottom: 20px;
}

.form .border-bottom {
  border-bottom: 1px solid #f0f0f0;
  margin-bottom: 25px;
}

.dashboard-content .edit-profile img {
  height: 100px;
  margin-bottom: 30px;
}

.form .ant-divider-horizontal {
  margin-top: 15px;
  margin-bottom: 30px;
}

.user-verification-page {
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;
  background: #FF0066;
}

.user-verification-page .btn {
  margin-top: 10px;
}

.user-verification-page .main-page-subtitle {
  display: flex;
  font-size: 14px;
  line-height: 24px;
  margin-top: 10px;
}

.user-verification-page .form-title{
  font-size: 20px;
  font-weight: 600;
}

.user-verification-card {
  text-align: center;
  width: 95%;
  max-width: 450px;
  padding: 24px;
  background: white;
  border-radius: 10px;
  box-shadow: 0 5px 10px rgb(154 160 185 / 5%), 0 15px 40px rgb(166 173 201 / 20%);
}

@media screen and (max-width: 768px) {
  .dashboard-content .form .form-row {
    display: block;
    padding-bottom: 0px;
  }
  .dashboard-content .site-layout-background.admin {
    padding: 10px;
  }
  .dashboard-content .site-layout-background {
    padding: 18px;
  }
  .dashboard-content .site-layout-background.shopping-list {
    padding: 10px 20px;
    /* height: calc(100vh - 350px); */
    overflow-y: scroll;
  }
  .dashboard-content .edit-profile .grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }
}