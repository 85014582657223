.expandable-card {
  padding: 24px;
  background: white;
  border-radius: 10px;
  cursor: pointer;
  // background-image: linear-gradient(141deg, #2bffcc, #4d2bff 39%, #FF0066);
  background-color: white;
  border-left: 10px solid transparent;
}

.expandable-card.completed {
  border-left: 10px solid #2bffcc;
}

.expandable-card.open {
  border-radius: 10px 10px 0 0;
}

.expandable-card .bold-text,
.open-card-content .bold-text{
  font-weight: 500;
  font-size: 15px;
  color: #000000;
  line-height: 15px;
}

.card-container .open-card-content{
  background: white;
  padding: 0 24px 24px 24px;
  border-radius: 0 0 10px 10px;
  border-left: 10px solid transparent;
}

.card-container .open-card-content.completed {
  border-left: 10px solid #2bffcc;
}

.card-container .open-card-content .global-card{
  margin-bottom: 10px;
}

.card-container .open-card-content.none{
  display: none;
}

.card-container:not(:first-child){
  margin-top: 10px;
}

.open-card-content .ant-divider {
  margin: 8px 0 8px 0;
}

.open-card-content .ant-divider:last-child {
  display: none;
}

.notify-coach-btn {
  width: 100%;
  margin-top: 10px;
}