.measurement-form-container .title {
  font-size: 25px;
  font-weight: 500px;
  color: black;
  margin-bottom: 20px;
}

.measurement-form {
  border-radius: 10px;
}

.measurement-form .form.body-fat-form {
  margin-bottom: 15px;
}

.measurement-form .reset-btn {
  margin-right: 20px;
  cursor: pointer;
}

.measurement-form .uploader {
  margin-top: 20px;
  margin-bottom: 20px;
}