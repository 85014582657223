.user-profile .user-profile-page{
  background: white;
  border-radius: 10px;
}

.user-profile .user-profile-page .profile-header-background {
  min-height: 220px;
  border-radius: 10px 10px 0 0;
}

.user-profile .user-profile-page .user-profile-container {
 padding: 0 100px 100px 100px;
}

.user-profile-page .user-profile-wrap {
  position: relative;
}
.user-profile-page .user-profile-image {
  width: 100px;
  height: 100px;
  border-radius: 100px;
  margin-top: -30px;
  border: 3px solid white;
  margin-right: 15px;
}
.user-profile-container .edit-profile-icon {
  position: absolute;
  height: 25px;
  width: 25px;
  border: 2px solid #fff;
  background-color: #2bffcc;
  border-radius: 50%;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 0;
  right: 18px;
}

.user-profile-page .user-profile-title {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: black;
}

.user-profile-page .user-profile-details {
  margin-top: 40px;
}

.user-profile-page .form-heading.profile {
  margin-bottom: 20px;
}


@media screen and (max-width: 768px) {
  .user-profile .user-profile-page .user-profile-container {
    padding: 0 18px 18px 18px;
   }
}