.blog-page .blog-sidebar .sidebar-card {
  grid-column: 2/5;
  padding: 50px 30px;
  background: white;
  border-radius: 10px;
  background: #fbfcfd;
  border: 1px solid #f2f2f2;
  margin-bottom: 40px;
}

.blog-sidebar .quiz-card-title {
  line-height: 25px;
}

.blog-sidebar .quiz-card-subtitle {
  margin: 5px 0 15px 0;
  border-radius: 0 0 10px 10px;
  font-size: 16px;
  border-top: 0;
  color: #405261;
  line-height: 1.5em;
  font-family: 'Montserrat', sans-serif;
}

.blog-sidebar ul {
  margin: 0px;
}

.blog-sidebar .blog-category {
  margin-top: 10px;
  font-size: 14px;  
  cursor: pointer;
  list-style-position: inside
}

.blog-sidebar .ant-input {
    padding: 15px;
    border-radius: 10px;
    height: 40px;
    font-size: 15px;
    color: black;
}

@media screen and (max-width: 768px) {
  .blog-page .blog-sidebar .sidebar-card {
    grid-column: unset;
    margin-bottom: 0px;
    height: 300px;
  }
  .blog-page .blog-sidebar .sidebar-card:last-child {
    margin-top: 20px;
  }
}

